import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../components/ImageUpload";
import { notifyError, notifySuccess } from "../../routes/ProtectedRoute";
import { AbilityContext } from "../../utils/context/can";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { createDeliveryBoy, getRole } from "./../../utils/api/delivery";
import styles from "./styles.module.css";

const formData = [
  {
    label: "First Name",
    name: "firstName",
    message: "first name",
    placeholder: "First Name",
  },
  {
    label: "Last Name",
    name: "lastName",
    message: "last name",
    placeholder: "Last Name",
  },
  {
    label: "Address",
    name: "address",
    message: "address",
    placeholder: "Address",
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    message: "phone number",
    placeholder: "Phone Number",
  },
  {
    label: "Email",
    name: "email",
    message: "Email",
    placeholder: "Email",
  },
  {
    label: "Password",
    name: "password",
    message: "password",
    placeholder: "Password",
  },
  {
    label: "Role",
    name: "role",
    message: "role",
    placeholder: "Role",
  },
];

function DeliveryBoy() {
  const { Option } = Select;

  const [photo, setPhoto] = useState([]);
  const [data, setData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [photoError, setPhotoError] = useState();
  const [loading, setLoading] = useState(false);
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();

  const validateRole = (rule, value, callback) => {
    if (value === "role") {
      callback("Role is required");
    } else {
      callback();
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  const handleSubmitFailed = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
  };

  const handleSubmit = (values, e) => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
    let fd = new FormData();
    fd.append("email", data.email);
    fd.append("name", data.firstName + " " + data.lastName);
    fd.append("address", data.address);
    fd.append("roles", data.role);
    fd.append("mobile_number", data.phoneNumber);
    fd.append("password", data.password);
    fd.append("profile_picture", photo[0].originFileObj);
    setLoading(true);

    createDeliveryBoy(fd)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setLoading(false);
          navigate({ pathname: "/viewusers/", search: `?type=${data.role}` });
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  const getRolesToSelect = (signal) => {
    getRole(signal)
      .then((res) => {
        if (res.status === "Success") {
          let filtered = res.data.filter((v) => {
            if (ability.can(v.name + "-create", "app")) {
              return v;
            }
          });
          setRoleList(filtered);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getRolesToSelect(abortCont.signal);
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Add User</h2>
          <p className={styles.subtitle}>Admin can create user from here</p>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onSubmit={(e) => e.preventDefault()}
      >
        <Row gutter={[, 12]}>
          {formData.map((item, index) => (
            <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} key={index}>
              {item.name === "password" ? (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 22 }}
                  // initialValue={data[item.name] || item.name}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                    {
                      min: 8,
                      message: "The password must be at least 8 characters.",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={item.placeholder}
                    value={data[item.name] || item.name}
                    autoComplete="new-password"
                    onChange={(e) => {
                      const value = { ...data };
                      value[`${item.name}`] = e.target.value;
                      setData(value);
                    }}
                  />
                </Form.Item>
              ) : item.name === "phoneNumber" ? (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  initialValue={data[item.name] || item.name}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                    {
                      max: 10,
                      min: 9,
                      message: "Phone number must be between 9 to 10 characters",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder={item.placeholder}
                    className={styles.numberArrow}
                    value={data[item.name] || item.name}
                    onChange={(e) => {
                      const value = { ...data };
                      value[`${item.name}`] = e.target.value;
                      setData(value);
                    }}
                  />
                </Form.Item>
              ) : item.name === "role" ? (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 22 }}
                  initialValue={data[item.name] || item.name}
                  // help={item.name === "role" ? "Please enter role" : null}
                  // validateStatus="error"
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                    { validator: validateRole },
                  ]}
                >
                  <Select
                    value={data[item.name]}
                    placeholder="Select Role"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      const value = { ...data };
                      value[`${item.name}`] = e;
                      setData(value);
                    }}
                  >
                    {roleList.map((dat, i) => (
                      <Option key={i} value={dat.name}>
                        {dat.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  initialValue={data[item.name]}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={item.placeholder}
                    value={data[item.name] || item.name}
                    onChange={(e) => {
                      const value = { ...data };

                      value[`${item.name}`] = e.target.value;
                      setData(value);
                    }}
                  />
                </Form.Item>
              )}
            </Col>
          ))}
        </Row>
        <Row style={{ marginTop: "12px" }}>
          <Col xl={{ span: 8 }} lg={{ span: 13 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item>
              <Form.Item
                name="dragger"
                valuePropName="fileList"
                listType="picture"
                getValueFromEvent={normFile}
                validateStatus="error"
                help={photo.length > 0 ? "" : photoError}
              >
                <ImageUpload fileList={photo} setFileList={setPhoto} />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit" size="large" loading={loading} disabled={loading}>
                Create <span style={{ marginLeft: "5px" }}>{data?.role}</span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default DeliveryBoy;
