import { getCookie } from "../cookie/cookies";
import { baseGetRequest, baseRequest } from "./base";

const getAllOrders = async (signal, status, page = 1, search = "") => {
  var url = `/api/all/shipments/${status}?page=${page}&shipment_unique_id=${search}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};
const singleOrder = async (id, signal) => {
  var url = `/api/shipments/${id}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const trackOrder = async (id, signal) => {
  var url = `/api/shipments/${id}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const acceptOrder = async (id) => {
  var url = `/api/accept/shipment/`;
  var response = await baseRequest(url + id, "POST", {}, getCookie("login-token"));
  return response;
};

const cancelOrder = async (id, body) => {
  var url = `/api/cancel/shipment/`;
  var response = await baseRequest(url + id, "POST", body, getCookie("login-token"));
  return response;
};

const getSearchOrder = async (status, search) => {
  var url = `/api/all/shipments/${status}?shipment_unique_id=${search}`;
  var response = await baseGetRequest(url, getCookie("login-token"));
  return response;
};

const getAllCODOrders = async (signal, status, page, search) => {
  var url = `/api/cod-shipments?payment_status=${status}&page=${page}&shipment_unique_id=${search}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const confirmPayment = async (id) => {
  var url = `/api/cod-shipments/${id}/payment`;
  var response = await baseRequest(url, "POST", { payment_status: "paid" }, getCookie("login-token"));
  return response;
};

export {
  getAllOrders,
  acceptOrder,
  cancelOrder,
  getSearchOrder,
  singleOrder,
  trackOrder,
  getAllCODOrders,
  confirmPayment,
};

//http://localhost:8000/api/delivery-boy/3/assign-delivery
