import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomerOrderTable from "../../components/Table/customerOrderTable";
import { notifyError } from "../../routes/ProtectedRoute";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import CustomPagination from "./../../components/Pagination";
import { getCustomerOrders } from "./../../utils/api/users";
import styles from "./styles.module.css";

const CustomerProfile = () => {
  const { Search } = Input;
  const tab = ["all", "completed", "processing", "cancelled"];

  const [selectedTab, setSelectedTab] = useState("all");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);

  const total = useRef(1);
  const lastPage = useRef(1);
  const perPage = useRef(1);

  const location = useLocation();

  const getCustomerData = async (signal) => {
    setLoading(true);
    getCustomerOrders(location.state.data, selectedTab, page, signal)
      .then((res) => {
        if (page === 1) {
          lastPage.current = res.data.last_page;
          perPage.current = res.data.orders.length;
          total.current = res.data.total;
        }
        setData(res.data.orders);
        setLoading(false);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getCustomerData(abortCont.signal);
    return () => abortCont.abort();
  }, [selectedTab, page, refresh]);

  return (
    <div className={styles.container}>
      <div className={styles.profileWrapper}>
        <div className={styles.leftProfile}>
          <div className={styles.topProfile}>
            <div className={styles.profileImage}>
              <img
                className={styles.image}
                src={
                  location.state.profile_picture
                    ? process.env.REACT_APP_SERVER_URL +
                      location.state.profile_picture
                    : `https://ui-avatars.com/api/?name=${location.state.name}&background=abcdef`
                }
              ></img>
            </div>
            <div className={styles.profileDetails}>
              <div className={styles.name}>{location.state.name}</div>
              <div className={styles.profileType}>{location.state.email}</div>
            </div>
          </div>
          <div className={styles.tabContainer}>
            {tab.map((tabItem, index) => (
              <div
                style={tabItem === selectedTab ? { color: "#009EF7" } : null}
                onClick={() => setSelectedTab(tabItem)}
                key={index}
              >
                {tabItem}{" "}
                {tabItem === selectedTab && <div className={styles.active} />}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightProfile}>
          <div></div>
          <div>
            <Search
              placeholder="input search text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <CustomerOrderTable
          data={data}
          page={page}
          perPage={perPage.current}
          setRefresh={setRefresh}
          selectedTab={selectedTab}
          loading={loading}
        />
        <CustomPagination
          pageSize={perPage.current}
          page={page}
          setPage={setPage}
          lastPage={lastPage.current}
          total={total.current}
        />
      </div>
    </div>
  );
};

export default CustomerProfile;
