import { getCookie } from "../cookie/cookies";
import { baseGetRequest, baseRequest } from "./base";

const getPermision = async (signal) => {
  var response = await baseGetRequest(`/api/all/permissions`, getCookie("login-token"), { signal: signal });
  return response;
};

const storeRoleWithPermission = async (data) => {
  var response = await baseRequest(`/api/roles`, "POST", data, getCookie("login-token"));
  return response;
};

const getUserPermissions = async (signal) => {
  var response = await baseGetRequest(`/api/user-permission`, getCookie("login-token"), { signal: signal });
  return response;
};

const getAllRoles = async (signal) => {
  var response = await baseGetRequest(`/api/roles`, getCookie("login-token"), { signal: signal });
  return response;
};

const getRoleById = async (id, signal) => {
  var response = await baseGetRequest(`/api/roles/${id}`, getCookie("login-token"), { signal: signal });
  return response;
};

const setRoleById = async (id, data, signal) => {
  var response = await baseRequest(`/api/roles/${id}`, "PUT", data, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

export { getPermision, storeRoleWithPermission, getUserPermissions, getAllRoles, getRoleById, setRoleById };
