import { baseGetRequest, baseRequest } from "./base";

const login = async (data) => {
  let loginData = {
    email: data.email,
    password: data.password,
    device_token: data.device_token,
  };

  var response = await baseRequest("/api/login", "POST", loginData);
  return response;
};

const logout = async () => {
  var response = await baseGetRequest("/api/logout");
  return response;
};

const isLogged = async () => {
  var response = await baseGetRequest("/api/logged-in");
  return response;
};

const updatePassword = async (data) => {
  var response = await baseRequest("/api/updatepassword", "POST", data);
  return response;
};

const forgotPassword = async (data) => {
  var response = await baseRequest("/api/forgotpassword", "POST", data);
  return response;
};

const resetPassword = async (data) => {
  var response = await baseRequest(`/api/changepassword`, "POST", data);
  return response;
};
export {
  login,
  logout,
  updatePassword,
  forgotPassword,
  isLogged,
  resetPassword,
};
