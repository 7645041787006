import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Input, Select, Table, Tag } from "antd";
import cx from "classnames";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { notifyError, notifySuccess } from "../../../routes/ProtectedRoute";
import { confirmPayment, getAllCODOrders } from "../../../utils/api/orders";
import { Can } from "../../../utils/context/can";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import Modal from "../../Modal";
import CustomPagination from "../../Pagination";
import ShowWarehouseModalDetails from "../../ShowWarehouseModalDetails";
import styles from "./styles.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case "not-assigned":
      return <CloseCircleOutlined />;

    case "cancelled":
      return <CloseCircleOutlined />;

    case "new":
      return <ClockCircleOutlined />;

    case "completed":
      return <CheckCircleOutlined />;
    case "cod":
      return "";
    case "delivery-only":
      return "";

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return "blue";

    case "not-assigned":
      return "purple";

    case "cancelled":
      return "red";

    case "new":
      return "gold";

    case "completed":
      return "green";
    case "cod":
      return "red";

    default:
      return "green";
  }
};

const paymentChooser = (tags) => {
  switch (tags) {
    case "not-received":
      return "yellow";

    case "received":
      return "purple";

    case "paid":
      return "green";

    case "cancelled":
      return "red";

    default:
      return "green";
  }
};

function ShowCashOnDeliveryTable() {
  const { Option } = Select;
  const { Search } = Input;
  const { width } = useWindowDimensions();

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
      fixed: true,
    },
    {
      title: "Shipment Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      fixed: true,
    },
    {
      title: "Shipment Charge",
      dataIndex: "shipment_charge",
      key: "shipment_charge",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (status, index) => (
        <Tag key={index} color={paymentChooser(status)} style={{ textTransform: "capitalize" }}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Payment Receiver",
      dataIndex: "payment_receiver",
      key: "payment_receiver",
    },
    {
      title: "Contact",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "Status",
      key: "shipment_status",
      dataIndex: "shipment_status",
      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "capitalize" }}
        >
          {status}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, item, id) =>
        selectedTab === "All" || selectedTab === "Received" ? (
          <div id="blue" className={styles.df}>
            <Can I="order-show" a="app">
              <Button
                type="primary"
                className={styles.button}
                onClick={() => {
                  setOpenView({ ...openView, open: true, data: item.id });
                }}
              >
                View
              </Button>
            </Can>
            {item.payment_status === "received" && (
              <Can I="cod-pay" a="app">
                <Button
                  type="primary"
                  className={styles.button}
                  onClick={() => {
                    handlePayment(item, item.id);
                  }}
                >
                  Pay
                </Button>
              </Can>
            )}
          </div>
        ) : (
          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              setOpenView({ ...openView, open: true, data: item.id });
            }}
          >
            View
          </Button>
        ),
    },
  ];
  const [refresh, setRefresh] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState({ open: false, data: null });
  const [search, setSearch] = useState("");

  const shipmentCount = useRef(1);
  const perPageCount = useRef(1);

  const navItemsConverter = (item) => {
    switch (item) {
      case "All Orders":
        return "all";

      case "Not Received":
        return "not-received";

      case "Received":
        return "received";

      case "Paid":
        return "paid";

      case "Cancelled":
        return "cancelled";

      default:
        return "all";
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

  const fetchOrderItems = async (signal) => {
    setLoading(true);
    await getAllCODOrders(signal, navItemsConverter(selectedTab), page, search)
      .then((res) => {
        if (res.status === "Success") {
          if (page === 1) {
            shipmentCount.current = res.data.total;
            perPageCount.current = res.data.orders.length;
            setLoading(false);
          }
          setOrderItems(res.data.orders);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  const handlePayment = (item, id) => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You want to confirm payment !!",
      html:
        "You want to confirm payment of" +
        " " +
        `Rs. ${item.amount}` +
        " " +
        "to" +
        " " +
        `${item.payment_receiver}?`,
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Ok",
      denyButtonText: `Discard`,
      confirmButtonColor: `green`,
      // confirmButtonColor: `var(--button-hover)`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {

        confirmPayment(id).then((res) => {
          if (res.status === "Success") {
            setRefresh((prev) => !prev);
            notifySuccess(res.message);
          }
        });

        // });
      }
    });
  };

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    const abortCont = new AbortController();
    fetchOrderItems(abortCont.signal);
    return () => abortCont.abort();
  }, [page, selectedTab, refresh, search]);

  const columnData = () => {
    return orderItems?.map((item, i) => ({
      ...item,
      shipments_unique_id: item.shipments_unique_id,
      shipment_charge: item.shipment_charge,
      amount: item.amount,
      payment_status: item.payment_status,
      payment_receiver: item.payment_receiver,
      mobile_number: item.mobile_number,
      shipment_status: item.shipment_status,
      key: item.shipments_unique_id,
      index: item.id,
      sn: (page - 1) * perPageCount.current + i + 1,
    }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className="displayFlex">
          {["All", "Not Received", "Received", "Paid", "Cancelled"].map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setPage(1);
                setSelectedTab(item);
              }}
              className={cx(styles.button, item === selectedTab ? styles.selected : null)}
            >
              {item}
            </div>
          ))}
        </div>
        <div>
          <Search placeholder="Search items" onChange={debouncedChangeHandler} />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        loading={loading}
        scroll={width < 1320 ? { x: 2000 } : null}
        dataSource={columnData()}
        style={{ cursor: "pointer" }}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />

      <Modal
        open={openView.open}
        onClose={() => setOpenView({ open: false })}
        style={
          modalLoading
            ? {
                padding: "1.4em",
                height: "600px",
                width: "875px",
                padding: "0 0 0.5em 0",
              }
            : {
                padding: "1.4em",
                height: "fit-content",
                width: "fit-content",
                padding: "0 0 0.5em 0",
              }
        }
      >
        <ShowWarehouseModalDetails
          open={openView?.open}
          data={openView?.data}
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />
      </Modal>
      <CustomPagination
        pageSize={perPageCount.current}
        page={page}
        setPage={setPage}
        total={shipmentCount.current}
      />
    </div>
  );
}

export default ShowCashOnDeliveryTable;
