import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import ImageUpload from "../../components/ImageUpload";
import { Can } from "../../utils/context/can";
import styles from "./styles.module.css";

const formData = [
  { label: "Name", name: "name", message: "name", placeholder: "Name" },
  {
    label: "Location",
    name: "location",
    message: "location",
    placeholder: "Location",
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    message: "phone number",
    placeholder: "Phone Number",
  },
  { label: "Email", name: "email", message: "email", placeholder: "Email" },
];

function Warehouse() {
  const [photo, setPhoto] = useState([]);
  const [photoError, setPhotoError] = useState();

  //Sets new image when image is changed or new image is uploaded
  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  const handleSubmitFailed = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
  };

  const handleSubmit = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Add Warehouse</h2>
          <p className={styles.subtitle}>User can add warehouse from here</p>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinishFailed={handleSubmitFailed}
        onFinish={handleSubmit}
      >
        <Row gutter={[, 12]}>
          {formData.map((item, index) => (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              key={index}
            >
              {item.name === "phoneNumber" ? (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder={item.placeholder}
                    className={styles.numberArrow}
                  />
                </Form.Item>
              ) : item.name === "email" ? (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input type="email" placeholder={item.placeholder} />
                </Form.Item>
              ) : (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input placeholder={item.placeholder} />
                </Form.Item>
              )}
            </Col>
          ))}
        </Row>
        <Row style={{ marginTop: "12px" }}>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item>
              <Form.Item
                name="dragger"
                valuePropName="fileList"
                listType="picture"
                getValueFromEvent={normFile}
                validateStatus="error"
                help={photo.length > 0 ? "" : photoError}
              >
                <ImageUpload fileList={photo} setFileList={setPhoto} />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item wrapperCol={{ span: 16 }}>
              <Can I="user-create" a="app">
                <Button type="primary" htmlType="submit" size="large">
                  Add User
                </Button>
              </Can>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Warehouse;
