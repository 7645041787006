import { getCookie } from "../cookie/cookies";
import { baseGetRequest } from "./base";

const getShipmentByWarehouse = async (id, signal, type, page, search) => {
  var url = `api/warehouse/${id}/${type}?page=${page}&shipment_unique_id=${search}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const getShipmentOfOwnWarehouse = async (signal, type, page, search) => {
  var url = `api/warehouse/${type}?page=${page}&shipment_unique_id=${search}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const getAllWarehouseShipments = async (id, signal) => {
  var url = `api/shipments/${id}/show`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

export {
  getShipmentByWarehouse,
  getAllWarehouseShipments,
  getShipmentOfOwnWarehouse,
};
