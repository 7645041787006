import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  MoreOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import Swal from "sweetalert2";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { cancelDelivery, completeOrder } from "../../../utils/api/delivery";
import { Can } from "../../../utils/context/can";
import { notifySuccess } from "./../../../routes/ProtectedRoute";
import Modal from "./../../Modal";
import OrderDetails from "./../../OrderDetails";
import styles from "./PendingTable.module.css";

// const MySwal = withReactContent(Swal);

function PendingTable({ data, page, perPage, setRefresh, selectedTab, loading }) {
  const tagChooser = (tags) => {
    switch (tags) {
      case "picked":
        return "blue";
      case "not-picked":
        return "yellow";
      case "cod":
        return "red";

      default:
        return "green";
    }
  };

  const iconChooser = (tags) => {
    switch (tags) {
      case "not-picked":
        return <SyncOutlined spin />;

      case "picked":
        return <CloseCircleOutlined />;

      case "new":
        return <ClockCircleOutlined />;

      case "delivered":
        return <CheckCircleOutlined />;

      default:
        return <CheckCircleOutlined />;
    }
  };

  const handleChecked = (index) => {
    return Swal.fire({
      // title: "Are you sure?",
      title: "Is the order has been delivered sucessfully ?",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      confirmButtonColor: "#1abc9c",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {

        completeOrder(index).then((res) => {
          if (res.status === "Success") {
            setRefresh((prev) => !prev);
            notifySuccess(res.message);
          }
        });

        // });
      }
    });
  };

  const handleCancel = (index) => {
    return Swal.fire({
      // title: "Are you sure?",
      title: "Do you want to cancel the delivery ?",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      confirmButtonColor: "#1abc9c",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {

        cancelDelivery(index).then((res) => {
          if (res.status === "Success") {
            setRefresh((prev) => !prev);
            notifySuccess(res.message);
          }
        });

        // });
      }
    });
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      responsive: ["md"],
      render: (text, item) => <span>{text}</span>,
    },
    {
      title: "Shipments Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Pick Location",
      dataIndex: "pick_location",
      key: "pick_location",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Drop Location",
      dataIndex: "drop_location",
      key: "drop_location",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Receiver Number",
      dataIndex: "receiver_number",
      key: "receiver_number",
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["md"],
      render: (tags) => (
        <Tag color={tagChooser(tags)} icon={iconChooser(tags)} style={{ textTransform: "capitalize" }}>
          {tags}
        </Tag>
      ),
    },
    {
      title: "Delivery Type",
      dataIndex: "delivery_type",
      key: "delivery_type",
      responsive: ["md"],
      render: (tags) => (
        <Tag color={tagChooser(tags)} style={{ textTransform: "capitalize" }}>
          {tags}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="displayFlex">
          {selectedTab === "pending" && (
            <Can I="delivery-complete" a="app">
              <Tooltip placement="bottom" title={"Complete"}>
                <CheckOutlined className={styles.icon} onClick={() => handleChecked(record.delivery_id)} />
              </Tooltip>
              <Tooltip placement="bottom" title={"Cancel"}>
                <CloseOutlined className={styles.icon} onClick={() => handleCancel(record.delivery_id)} />
              </Tooltip>
            </Can>
          )}
          <Can I="order-show" a="app">
            <Tooltip placement="bottom" title={"View"}>
              <EyeOutlined
                className={styles.icon}
                onClick={() => {
                  setOpenView({ ...openView, open: true, data: record });
                }}
              />
            </Tooltip>
          </Can>
        </div>
      ),
    },
  ];

  const [openView, setOpenView] = useState({ open: false, data: {} });
  const { width } = useWindowDimensions();

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>Order Table</div>
        <div>
          <MoreOutlined className={styles.icon} />
        </div>
      </div>
      <Table
        className={styles.table}
        scroll={width < 1320 ? { x: 2000 } : null}
        columns={columns}
        loading={loading}
        dataSource={data.map((dat, i) => {
          return {
            ...dat,
            sn: (page - 1) * perPage + i + 1,
          };
        })}
        pagination={false}
        style={{ cursor: "pointer" }}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
      <Modal
        open={openView.open}
        onClose={() => setOpenView({ open: false, data: {} })}
        style={{
          padding: "1.4em",
          height: "fit-content",
          width: "fit-content",
        }}
      >
        <OrderDetails data={openView?.data} />
      </Modal>
    </div>
  );
}

export default PendingTable;
