import { CalendarOutlined } from "@ant-design/icons";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";

const data = [
  { name: "Today", color: "none", value: 0 },
  { name: "Last 7 Days", color: "none", value: 7 },
  { name: "Last 30 Days", color: "none", value: 30 },
  { name: "Last 365 Days  ", color: "none", value: 365 },
];

function Header({ startDate, endDate, onDateChange }) {
  const [selected, setSelected] = useState(data[0].value);

  useEffect(() => {
    var today = new Date();
    const previous = new Date(today.getTime());
    previous.setDate(today.getDate() - selected);
    onDateChange(previous, today);
  }, [selected]);

  return (
    <div className={styles.header}>
      <div className={styles.dashboard}>Dashboard</div>
      <div className={styles.leftWrapper}>
        <div className={styles.calender}>
          {data.map((dat, i) => (
            <div
              key={i}
              className={cx(
                styles.column,
                selected === dat.value ? styles.selected : null
              )}
              onClick={() => setSelected(dat.value)}
            >
              {dat.name}
              {i !== data.length - 1 && <div className={styles.left} />}
            </div>
          ))}
        </div>
        <div className={styles.calender}>
          <div className={styles.column}>
            <CalendarOutlined />{" "}
            <span>
              {startDate?.toLocaleDateString(undefined, {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}{" "}
              -{" "}
              {endDate?.toLocaleDateString(undefined, {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
