import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Input, Radio, Select, Table, Tag } from "antd";
import cx from "classnames";
import debounce from "lodash.debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { notifyError, notifySuccess } from "../../../routes/ProtectedRoute";
import { assignDelivery, completeOrder, getDeliveryBoys } from "../../../utils/api/delivery";
import { cancelOrder } from "../../../utils/api/orders";
import { getAllWareHouse } from "../../../utils/api/users";
import { getShipmentOfOwnWarehouse } from "../../../utils/api/warehouse";
import { Can } from "../../../utils/context/can";
import { getDateTime } from "../../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import Modal from "../../Modal";
import QRModal from "../../Modal/QRModal";
import CustomPagination from "../../Pagination";
import ShowWarehouseModalDetails from "../../ShowWarehouseModalDetails";
import AssigntaskTableDashboard from "../assignTaskDashboard/AssignTaskTableDashboard";
import styles from "./styles.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case "not-assigned":
      return <CloseCircleOutlined />;

    case "cancelled":
      return <CloseCircleOutlined />;

    case "new":
      return <ClockCircleOutlined />;

    case "completed":
      return <CheckCircleOutlined />;
    case "cod":
      return "";
    case "delivery-only":
      return "";

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return "blue";

    case "not-assigned":
      return "purple";

    case "cancelled":
      return "red";

    case "new":
      return "gold";

    case "completed":
      return "green";
    case "cod":
      return "red";

    default:
      return "green";
  }
};

function ShowMyWarehouseTable() {
  const { Option } = Select;
  const { Search } = Input;
  const { width } = useWindowDimensions();

  const incomingColumns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
      fixed: true,
    },
    {
      title: "Shipment Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      fixed: true,
    },
    {
      title: "Current Location",
      dataIndex: "last_location",
      key: "last_location",
    },
    {
      title: "Dropoff Location",
      dataIndex: "dropoff",
      key: "dropoff",
    },
    {
      title: "Receiver Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Receiver Name",
      dataIndex: "receiver_name",
      key: "receiver_name",
      responsive: ["xxl"],
    },
    {
      title: "Order Placed Date",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["xxl"],
      render: (value) => <>{getDateTime(value).date}</>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "capitalize" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Delivery Type",
      key: "delivery_type",
      dataIndex: "delivery_type",

      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "uppercase" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "QR",
      key: "QR",
      dataIndex: "QR",
      render: (text, item, id) => (
        <Button
          icon={<EyeOutlined />}
          shape="circle"
          onClick={() => {
            setQrItem(item);
            showQRModal();
          }}
        ></Button>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, item, id) => (
        <div className={styles.buttonContainer}>
          <Can I="order-show" a="app">
            <Button
              type="primary"
              onClick={() => {
                setOpenView({ ...openView, open: true, data: item.id });
              }}
            >
              View
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleArrival(item.delivery_id);
              }}
            >
              Mark as arrived
            </Button>
          </Can>
        </div>
      ),
    },
  ];

  const stockColumns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
      fixed: true,
    },
    {
      title: "Shipment Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      fixed: true,
    },

    {
      title: "Dropoff Location",
      dataIndex: "dropoff",
      key: "dropoff",
    },
    {
      title: "Receiver Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Receiver Name",
      dataIndex: "receiver_name",
      key: "receiver_name",
      responsive: ["xxl"],
    },

    {
      title: "Arrival Date",
      dataIndex: "arrival_date",
      key: "arrival_date",
      render: (value) => <>{getDateTime(value).date}</>,
    },
    {
      title: "Order Placed Date",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["xxl"],
      render: (value) => <>{getDateTime(value).date}</>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "capitalize" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Delivery Type",
      key: "delivery_type",
      dataIndex: "delivery_type",

      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "uppercase" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "QR",
      key: "QR",
      dataIndex: "QR",
      render: (text, item, id) => (
        <Button
          icon={<EyeOutlined />}
          shape="circle"
          onClick={() => {
            setQrItem(item);
            showQRModal();
          }}
        ></Button>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, item, id) => (
        <div className="displayFlex">
          <Button
            type="primary"
            onClick={() => {
              setOpenView({ ...openView, open: true, data: item.id });
            }}
          >
            View
          </Button>
          <Can I="delivery-assign" a="app">
            {item.shipment_status === "not-assigned" && (
              <Button
                type="primary"
                className={styles.button}
                onClick={() => {
                  setOpen((dat) => !dat);
                  setLastLocation(item.last_location);
                  setShipmentDetails({
                    shipment_ids: [item.id],
                    receive_from: item.last_location,
                  });
                }}
              >
                Assign
              </Button>
            )}
          </Can>
        </div>
      ),
    },
  ];

  const historyColumns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
      fixed: true,
    },
    {
      title: "Shipment Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      fixed: true,
    },

    {
      title: "Dropoff Location",
      dataIndex: "dropoff",
      key: "dropoff",
    },
    {
      title: "Receiver Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Receiver Name",
      dataIndex: "receiver_name",
      key: "receiver_name",
      responsive: ["xxl"],
    },

    {
      title: "Arrival Date",
      dataIndex: "arrival_date",
      key: "arrival_date",
      render: (value) => <>{getDateTime(value).date}</>,
    },
    {
      title: "Departure Date",
      dataIndex: "departure_date",
      key: "departure_date",
      render: (value) => <>{getDateTime(value).date}</>,
    },
    ,
    {
      title: "Order Placed Date",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["xxl"],
      render: (value) => <>{getDateTime(value).date}</>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "capitalize" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Delivery Type",
      key: "delivery_type",
      dataIndex: "delivery_type",

      render: (status, index) => (
        <Tag
          key={index}
          color={tagChooser(status)}
          icon={iconChooser(status)}
          style={{ textTransform: "uppercase" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "QR",
      key: "QR",
      dataIndex: "QR",
      render: (text, item, id) => (
        <Button
          icon={<EyeOutlined />}
          shape="circle"
          onClick={() => {
            setQrItem(item);
            showQRModal();
          }}
        ></Button>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, item, id) => (
        <Button
          type="primary"
          onClick={() => {
            setOpenView({ ...openView, open: true, data: item.id });
          }}
        >
          View
        </Button>
      ),
    },
  ];
  const [refresh, setRefresh] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [qrItem, setQrItem] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [deliveryBoyPage, setDeliveryBoyPage] = useState(1);
  const [deliveryBoyLoading, setDeliveryBoyLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("incomings");
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [assignUsers, setAssignUser] = useState([]);
  const [data, setData] = useState();
  const [openView, setOpenView] = useState({ open: false, data: null });
  const [search, setSearch] = useState("");
  const [receiver, setReceiver] = useState("warehouse");
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [lastLocation, setLastLocation] = useState();
  const [error, setError] = useState({ err: false, data: [] });

  const deliveryBoyCount = useRef(1);
  const shipmentCount = useRef(1);
  const perPageCount = useRef(1);

  const handleArrival = (id) => {
    return Swal.fire({
      // title: "Are you sure?",
      title: "Do you want to mark the delivery as arrived ?",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      confirmButtonColor: "#1abc9c",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {

        completeOrder(id).then((res) => {
          if (res.status === "Success") {
            setRefresh((prev) => !prev);
            notifySuccess(res.message);
          }
        });

        // });
      }
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

  const handleReceiverChange = (e) => {
    setReceiver(e.target.value);
    // setSelectedWarehouse(null);
  };

  function handleWarehouseChange(value) {
    var temp = warehouse.filter((dat, i) => dat.id === value);
    if (temp.length > 0) {
      setSelectedWarehouse(temp[0]);
    }
  }

  const showQRModal = () => {
    setOpenQRModal(true);
  };

  const closeQRModal = () => {
    setOpenQRModal(false);
  };

  const handleCancel = (index) => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel !!",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Ok",
      denyButtonText: `Discard`,
      confirmButtonColor: `green`,
      // confirmButtonColor: `var(--button-hover)`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {

        cancelOrder(index).then((res) => {
          if (res.status === "Success") {
            setRefresh((prev) => !prev);
            notifySuccess(res.message);
          }
        });

        // });
      }
    });
  };

  const navItems = [
    { title: "Incomings", key: "incomings" },
    { title: "In-warehouse", key: "stock" },
    { title: "History", key: "history" },
  ];

  const fetchOrderItems = async (signal) => {
    setLoading(true);
    await getShipmentOfOwnWarehouse(signal, selectedTab, page, search)
      .then((res) => {
        if (res.status === "Success") {
          if (page === 1) {
            shipmentCount.current = res.data.total;
            perPageCount.current = res.data.orders.length;
            setLoading(false);
          }
          setOrderItems(res.data.orders);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  const fetchAllDeliveryBoys = (signal) => {
    setDeliveryBoyLoading(true);
    getDeliveryBoys("all", deliveryBoyPage, "", signal)
      .then((res) => {
        if (res.status === "Success") {
          if (deliveryBoyPage === 1) {
            deliveryBoyCount.current = res.data.total;
            setDeliveryBoyLoading(false);
          }
          setAssignUser([...res.data.delivery_boys]);
          setData(
            res.data.delivery_boys.map((dat) => ({
              ...dat,
              is_selected: false,
            }))
          );
          setDeliveryBoyLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setDeliveryBoyLoading(false);
        }
      })
      .finally(() => {});
  };

  const assignTask = () => {
    const istrue = data.every((dat) => dat.is_selected === false);
    if (istrue) {
      return;
    }
    const temp = data.filter((dat) => dat.is_selected === true);
    if (receiver === "warehouse" && !selectedWarehouse) {
      setError({ err: true, data: [...error.data, "Please select warehouse"] });
      return;
    }

    let sendingData = {};
    if (receiver === "warehouse") {
      sendingData = {
        ...shipmentDetails,
        destination: "warehouse",
        delivery_warehouse_id: selectedWarehouse.id,
      };
    }
    if (receiver === "receiver") {
      sendingData = {
        ...shipmentDetails,
        destination: "receiver",
      };
    }
    assignDelivery(sendingData, temp[0].user_id)
      .then((res) => {
        notifySuccess(res.message);
        setRefresh((prev) => !prev);
        setOpen(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    const abortCont = new AbortController();
    fetchOrderItems(abortCont.signal);
    return () => abortCont.abort();
  }, [page, selectedTab, refresh, search]);

  useEffect(() => {
    const abortCont = new AbortController();
    fetchAllDeliveryBoys(abortCont.signal);
    return () => abortCont.abort();
  }, [open, deliveryBoyPage]);

  useEffect(() => {
    const abortCont = new AbortController();
    getAllWareHouse(abortCont.signal)
      .then((res) => {
        setWarehouse(res.data);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
    return () => abortCont.abort();
  }, []);

  const columnData = () => {
    if (selectedTab === "incomings") {
      return orderItems?.map((item, i) => ({
        ...item,
        current_location: item.last_location,
        dropoff: item.drop_location,
        status: item.shipment_status,
        contact: item.receiver_number,
        key: item.shipments_unique_id,
        index: item.id,
        sn: (page - 1) * perPageCount.current + i + 1,
        rn: item?.user?.name,
      }));
    } else if (selectedTab === "stock") {
      return orderItems?.map((item, i) => ({
        ...item,
        dropoff: item.drop_location,
        status: item.shipment_status,
        arrival_date: item.arrival_date,
        contact: item.receiver_number,
        key: item.shipments_unique_id,
        index: item.id,
        sn: (page - 1) * perPageCount.current + i + 1,
        rn: item?.user?.name,
      }));
    } else {
      return orderItems?.map((item, i) => ({
        ...item,
        dropoff: item.drop_location,
        status: item.shipment_status,
        arrival_date: item.arrival_date,
        departure_date: item.departure_date,
        contact: item.receiver_number,
        key: item.shipments_unique_id,
        index: item.id,
        sn: (page - 1) * perPageCount.current + i + 1,
        rn: item?.user?.name,
      }));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className="displayFlex">
          {navItems.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setPage(1);
                setSelectedTab(item.key);
              }}
              className={cx(styles.button, item.key === selectedTab ? styles.selected : null)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div>
          <Search placeholder="Search items" onChange={debouncedChangeHandler} />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={
          selectedTab === "incomings"
            ? incomingColumns
            : selectedTab === "stock"
            ? stockColumns
            : historyColumns
        }
        loading={loading}
        scroll={width < 1320 ? { x: 2000 } : null}
        dataSource={columnData()}
        style={{ cursor: "pointer" }}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
      <QRModal closeQRModal={closeQRModal} openQRModal={openQRModal} qrItem={qrItem} />
      <Modal
        open={open}
        onClose={() => {
          setSelectedWarehouse(null);
          setOpen(false);
        }}
        style={{ height: "fit-content" }}
      >
        {/* <div className={styles.subtitle}>
          Product List Will be here and can be assign here
        </div> */}
        <div className={styles.pickupTitle}>Pickup Location: {lastLocation}</div>
        <div className={styles.heading}>
          <div>
            <div className={styles.modalTitle}>Please select the drop location</div>
            <div className={styles.margin1} />
            <div className={styles.radiobutton}>
              <Radio.Group onChange={handleReceiverChange} value={receiver}>
                <Radio value={"warehouse"}>Warehouse</Radio>
                <Radio value={"receiver"}>Receiver</Radio>
              </Radio.Group>
            </div>
            <div className={styles.margin} />
            {receiver === "warehouse" && (
              <Select
                value={selectedWarehouse ? selectedWarehouse.id : "Please select warehouse"}
                style={{ width: "100%" }}
                onChange={handleWarehouseChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {warehouse
                  .filter((value) => value.name !== shipmentDetails.receive_from)
                  .map((dat, i) => (
                    <Option key={i} value={dat.id}>
                      {dat.name}
                    </Option>
                  ))}
              </Select>
            )}
          </div>
          <div className="displayFlex">
            <div className={styles.selected}>
              <Button type="primary" onClick={assignTask}>
                Assign Rider
              </Button>
            </div>
          </div>
        </div>
        <AssigntaskTableDashboard
          loading={deliveryBoyLoading}
          assignUsers={assignUsers}
          setShipmentDetails={setShipmentDetails}
          shipmentDetails={shipmentDetails}
          data={data}
          setData={setData}
          perPage={deliveryBoyCount.current}
          page={deliveryBoyPage}
        >
          <div style={{ marginTop: "2em" }}>
            <CustomPagination
              pageSize={perPageCount.current}
              page={deliveryBoyPage}
              setPage={(page) => {
                setDeliveryBoyPage(page);
              }}
              total={deliveryBoyCount.current}
            />
          </div>
        </AssigntaskTableDashboard>
        {error.err === true && <div></div>}
      </Modal>
      <Modal
        open={openView.open}
        onClose={() => setOpenView({ open: false })}
        style={{
          padding: "1.4em",
          height: "fit-content",
          width: "fit-content",
          padding: "0 0 0.5em 0",
        }}
      >
        <ShowWarehouseModalDetails open={openView?.open} data={openView?.data} />
      </Modal>
      <CustomPagination
        pageSize={perPageCount.current}
        page={page}
        setPage={setPage}
        total={shipmentCount.current}
      />
    </div>
  );
}

export default ShowMyWarehouseTable;
