import React from "react";
import MarkCompleteTable from "../../components/Table/markCompleteTable";
import styles from "./styles.module.css";

function MarkComplete() {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>All order items</h2>
          <p className={styles.subtitle}>Admin can see all ordered items</p>
        </div>
      </div>
      <div>
        <MarkCompleteTable />
      </div>
    </div>
  );
}

export default MarkComplete;
