import { Button, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError } from "../../../routes/ProtectedRoute";
import { getAllRoles } from "../../../utils/api/role";
import { Can } from "../../../utils/context/can";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import styles from "./styles.module.css";

function ViewRoleTable() {
  const { Search } = Input;
  const navigate = useNavigate();
  const location = useLocation();

  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Action",
      key: "action",
      render: (text, item) => (
        <Can I="role-edit" a="app">
          <div className="displayFlex">
            <Button
              type="primary"
              className={styles.editButton}
              onClick={() => {
                navigate("/role/update/", {
                  state: {
                    data: item,
                  },
                });
              }}
            >
              Edit
            </Button>
          </div>
        </Can>
      ),
    },
  ];

  //states
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getRoles = async (signal) => {
    setLoading(true);
    await getAllRoles(signal)
      .then((res) => {
        if (res.status === "Success") {
          setRoles(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getRoles(abortCont.signal);
    return () => abortCont.abort();
  }, [refresh]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>
          <Search
            placeholder="Search User"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        loading={loading}
        dataSource={roles.map((dat, i) => {
          return {
            ...dat,
            sn: i + 1,
            key: i,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
    </div>
  );
}

export default ViewRoleTable;
