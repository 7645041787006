import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ViewRoleTable from "../../components/Table/viewRoleTable";
import { Can } from "../../utils/context/can";
import styles from "./styles.module.css";

function ViewRoles() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Roles List</h2>
          <p className={styles.subtitle}>Admin can view and edit roles from here</p>
        </div>
        <Can I="role-create" a="app">
          <Button type="primary" size="large" className={styles.button} onClick={() => navigate("/create/role/")}>
            Add Role
          </Button>
        </Can>
      </div>
      <div className={styles.table}>
        <ViewRoleTable />
      </div>
    </div>
  );
}

export default ViewRoles;
