import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Image, Tag, Typography } from "antd";
import cx from "classnames";
import React from "react";
import { getDateTime } from "../../utils/dateTime/dateAndTime";
import styles from "./styles.module.css";

const { Title } = Typography;

function Index({ data }) {
  const tagChooser = (tags) => {
    switch (tags) {
      case "assigned":
        return "blue";

      case "not-assigned":
        return "purple";

      case "cancelled":
        return "red";

      case "new":
        return "gold";

      case "completed":
        return "green";
      case "delivery-only":
        return "green";
      case "cod":
        return "red";

      default:
        return "green";
    }
  };

  const iconChooser = (tags) => {
    switch (tags) {
      case "assigned":
        return <SyncOutlined spin />;

      case "not-assigned":
        return <CloseCircleOutlined />;

      case "cancelled":
        return <CloseCircleOutlined />;

      case "new":
        return <ClockCircleOutlined />;

      case "completed":
        return <CheckCircleOutlined />;

      default:
        return <CheckCircleOutlined />;
    }
  };

  return (
    <div className={cx(styles.container, data?.delivery_type === "cod" ? styles.cod : styles.notCod)}>
      <div className={styles.detailscontain}>
        {data?.delivery_type === "cod" && (
          <div className={styles.img}>
            <Image className={styles.image} src={process.env.REACT_APP_SERVER_URL + data?.bill}></Image>
          </div>
        )}
        <div>Status:</div>
        <Tag
          color={tagChooser(data?.shipment_status)}
          icon={iconChooser(data?.shipment_status)}
          style={
            tagChooser(data?.shipment_status) === "purple"
              ? { maxWidth: "120px" }
              : tagChooser(data?.status) === "green" ||
                tagChooser(data?.shipment_status) === "blue" ||
                tagChooser(data?.shipment_status) === "red"
              ? { maxWidth: "100px" }
              : { maxWidth: "70px" }
          }
        >
          {data?.shipment_status}
        </Tag>
        <div>Shipments Id:</div>
        <div>{data?.shipments_unique_id}</div>
        <div>Pick Location:</div>
        <div>{data?.pick_location}</div>
        <div> Drop Location:</div>
        <div>{data?.drop_location}</div>
        <div>Sender Name:</div>
        <div>{data?.user?.name}</div>
        <div>Phone :</div>
        <div>{data?.user?.mobile_number}</div>
        <div>Order Date:</div>
        <div>{getDateTime(data?.created_at).date}</div>
        <div>Order Time:</div>
        <div>{getDateTime(data?.created_at).time}</div>
        <div>Reciever Name:</div>
        <div>{data?.receiver_name}</div>
        <div>Receiver No:</div>
        <div>{data?.receiver_number}</div>
        <div>Current Location:</div>
        <div>{data?.last_location}</div>
        <div>Delivery Type:</div>
        <div className={styles.textCapital}>
          <Tag color={tagChooser(data?.delivery_type)} icon={iconChooser(data?.delivery_type)}>
            {data?.delivery_type}
          </Tag>
        </div>
        {data?.delivery_type === "cod" && <div>Total:</div>}
        {data?.delivery_type === "cod" && <div>Rs:{data?.total}</div>}
        {data?.delivery_type === "cod" && <div>Shipment Charge:</div>}
        {data?.delivery_type === "cod" && <div>Rs: {data?.shipment_charge}</div>}
        {data?.delivery_type === "cod" && <div>Payment Status:</div>}
        {data?.delivery_type === "cod" && <div>{data?.payment_status}</div>}
      </div>
      <div className={styles.cancelContainer}>
        {data?.status === "cancelled" && <div className={styles.cancelTitle}>Cancellation Message:</div>}
        {data?.status === "cancelled" && <div className={styles.cancelMessage}>{data?.cancelation_message}</div>}
      </div>

      <div className={styles.margin} />
      <Title level={3}>Item</Title>

      <table>
        <tbody>
          <tr className={styles.headerTable}>
            <td className={styles.td}>ID</td>
            <td className={styles.td}>Item Name</td>
            <td className={styles.td}>Item Quantity</td>
            <td className={styles.td}>Item Price</td>
          </tr>
          {data?.shipmentitems?.map((dat, i) => (
            <tr key={i} className={i % 2 === 0 ? styles.tableBodyColorEven : styles.tableBodyColorOdd}>
              <td className={styles.td}>{dat.id}</td>
              <td className={styles.td}>{dat.item_name}</td>
              <td className={styles.td}>{dat.item_quantity}</td>
              <td className={styles.td}>{dat.item_price}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.margin} />
      <Title level={3}>Delivery Log</Title>

      <table className={styles.table}>
        <tbody>
          <tr className={styles.headerTable}>
            <td className={styles.td}>S.N.</td>
            <td className={styles.td}>Delivery Boy</td>
            <td className={styles.td}>Contact</td>
            <td className={styles.td}>Pickup Address</td>
            <td className={styles.td}>Delivery Address</td>
            <td className={styles.td}>Delivered Time</td>
            <td className={styles.td}>Status</td>
          </tr>
          {data?.deliveries?.map((dat, i) => (
            <tr key={i} className={i % 2 === 0 ? styles.tableBodyColorEven : styles.tableBodyColorOdd}>
              <td className={styles.td}>{i + 1}</td>
              <td className={styles.td}>{dat?.deliveryboy?.name}</td>
              <td className={styles.td}>{dat?.deliveryboy?.mobile_number}</td>
              <td className={styles.td}>{dat?.receive_from}</td>
              <td className={styles.td}>{dat?.deliver_to}</td>
              <td className={styles.td}>{dat?.delivered_time ? dat.delivered_time : "Not Delivered"}</td>

              <td className={styles.td}>{dat.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Index;
