import { Ability } from "@casl/ability";

const initialAbility = [];
// import { getAbility } from "@ability/../getAbility";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

// const exability = getAbility(
//   JSON.parse(localStorage.getItem("userData"))?.activeRestaurant?.role || [
//     "User",
//   ]
// );

export default new Ability(initialAbility);
