import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Radio, Select, Table, Tag } from "antd";
import { Option } from "antd/lib/mentions";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { notifyError, notifySuccess } from "../../../routes/ProtectedRoute";
import { assignDelivery, getDeliveryBoys } from "../../../utils/api/delivery";
import { cancelOrder } from "../../../utils/api/orders";
import { Can } from "../../../utils/context/can";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import Modal from "../../Modal";
import OrderDetails from "../../OrderDetails";
import CustomPagination from "../../Pagination";
import AssigntaskTableDashboard from "../assignTaskDashboard/AssignTaskTableDashboard";
import { getAllWareHouse } from "./../../../utils/api/users";
import styles from "./styles.module.css";

function CustomerOrderTable({ data, page, perPage, setRefresh, selectedTab, loading }) {
  const tagChooser = (tags) => {
    switch (tags) {
      case "new":
        return "yellow";
      case "assigned":
        return "blue";
      case "not-assigned":
        return "purple";
      case "completed":
        return "green";
      case "cancelled":
        return "red";
      default:
        return "green";
    }
  };

  const iconChooser = (tags) => {
    switch (tags) {
      case "not-assigned":
        return <CloseCircleOutlined />;
      case "assigned":
        return <SyncOutlined spin />;
      case "cancelled":
        return <CloseCircleOutlined />;

      case "new":
        return <ClockCircleOutlined />;

      case "completed":
        return <CheckCircleOutlined />;

      default:
        return <CheckCircleOutlined />;
    }
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      responsive: ["md"],
      render: (text, item) => <span>{text}</span>,
    },
    {
      title: "Shipments Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Pick Location",
      dataIndex: "pick_location",
      key: "pick_location",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Drop Location",
      dataIndex: "drop_location",
      key: "drop_location",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Contact",
      dataIndex: "receiver_number",
      key: "phone",
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "shipment_status",
      key: "status",
      responsive: ["md"],
      render: (tags) => (
        <Tag
          color={tagChooser(tags)}
          icon={iconChooser(tags)}
          style={{ textTransform: "capitalize" }}
        >
          {tags}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, item) => (
        <>
          {item.shipment_status === "new" || item.shipment_status === "not-assigned" ? (
            <div className={styles.buttonGroup}>
              <Can I="order-show" a="app">
                <Button
                  type="primary"
                  className={styles.button}
                  onClick={() => {
                    setOpenView({ ...openView, open: true, data: item });
                  }}
                >
                  View
                </Button>
              </Can>
              <Can I="delivery-assign" a="app">
                <Button
                  type="primary"
                  className={styles.button}
                  onClick={() => {
                    setOpen((dat) => !dat);
                    setLastLocation(item.last_location);
                    setShipmentDetails({
                      shipment_ids: [item.id],
                      receive_from: item.last_location,
                      deliver_to: item.drop_location,
                    });
                  }}
                >
                  Assign
                </Button>
              </Can>
              <Can I="order-cancel" a="app">
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    handleCancel(item.id);
                  }}
                >
                  Cancel
                </Button>
              </Can>
            </div>
          ) : (
            <Button
              type="primary"
              className={styles.button}
              onClick={() => {
                setOpenView({ ...openView, open: true, data: item });
              }}
            >
              View
            </Button>
          )}
        </>
      ),
    },
  ];

  const [openView, setOpenView] = useState({ open: false, data: {} });
  const [open, setOpen] = useState(false);
  const [rvalue, setValue] = useState("warehouse");
  const [warehouse, setWarehouse] = useState([]);
  const [selectWarehouse, setSelectedWarehouse] = useState([]);
  const [error, setError] = useState({ err: false, data: [] });
  const [deliveryBoyPage, setDeliveryBoyPage] = useState(1);
  const [deliveryBoyLoading, setDeliveryBoyLoading] = useState(false);
  const [assignUsers, setAssignUser] = useState([]);
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [taskData, setTaskData] = useState();
  const [lastLocation, setLastLocation] = useState();
  const deliveryBoyCount = useRef(1);
  const perPageCount = useRef(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  function handleChange(value) {
    setSelectedWarehouse(warehouse.filter((dat, i) => dat.id === value));
  }

  const handleCancel = (index) => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel !!",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Ok",
      denyButtonText: `Discard`,
      confirmButtonColor: `green`,
      // confirmButtonColor: `var(--button-hover)`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {

        cancelOrder(index).then((res) => {
          if (res.status === "Success") {
            setRefresh((prev) => !prev);
            notifySuccess(res.message);
          }
        });

        // });
      }
    });
  };

  const assignTask = () => {
    const istrue = taskData.every((dat) => dat.is_selected === false);
    if (istrue) {
      return;
    }
    const temp = taskData.filter((dat) => dat.is_selected === true);
    if (rvalue === "warehouse" && selectWarehouse.length !== 1) {
      setError({ err: true, data: [...error.data, "Please select warehouse"] });
      return;
    }

    let sendingData = {};
    if (rvalue === "warehouse") {
      sendingData = {
        ...shipmentDetails,
        deliver_to: selectWarehouse[0].name + ", " + selectWarehouse[0].address,
      };
    }
    if (rvalue === "receiver") {
      sendingData = {
        ...shipmentDetails,
        deliver_to: "receiver",
      };
    }
    assignDelivery(sendingData, temp[0].user_id)
      .then((res) => {
        notifySuccess(res.message);
        setRefresh((prev) => !prev);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllDeliveryBoys = (signal) => {
    setDeliveryBoyLoading(true);
    getDeliveryBoys("all", deliveryBoyPage, "", signal)
      .then((res) => {
        if (res.status === "Success") {
          if (deliveryBoyPage === 1) {
            deliveryBoyCount.current = res.data.total;
            setDeliveryBoyLoading(false);
          }
          setAssignUser([...res.data.delivery_boys]);
          setDeliveryBoyLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setDeliveryBoyLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchAllDeliveryBoys(abortCont.signal);
    return () => abortCont.abort();
  }, [open, deliveryBoyPage]);

  useEffect(() => {
    setTaskData(assignUsers.map((dat) => ({ ...dat, is_selected: false })));
  }, [open, assignUsers]);

  useEffect(() => {
    const abortCont = new AbortController();
    getAllWareHouse(abortCont.signal)
      .then((res) => {
        setWarehouse(res.data);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>Order Table</div>
        <div>
          <MoreOutlined className={styles.icon} />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        loading={loading}
        dataSource={data.map((dat, i) => {
          return {
            ...dat,
            sn: (page - 1) * perPage + i + 1,
          };
        })}
        pagination={false}
        style={{ cursor: "pointer" }}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
      <Modal open={open} onClose={() => setOpen(false)} style={{ height: "fit-content" }}>
        <div className={styles.pickupTitle}>Pickup Location: {lastLocation}</div>
        <div className={styles.heading}>
          <div>
            <div className={styles.modalTitle}>Please select the drop location</div>
            <div className={styles.margin1} />
            <div className={styles.radiobutton}>
              <Radio.Group onChange={onChange} value={rvalue}>
                <Radio value={"warehouse"}>Warehouse</Radio>
                <Radio value={"receiver"}>Receiver</Radio>
              </Radio.Group>
            </div>
            <div className={styles.margin} />
            {rvalue === "warehouse" && (
              <Select
                defaultValue="Please select warehouse"
                style={{ width: "100%" }}
                onChange={handleChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {warehouse.map((dat, i) => (
                  <Option key={i} value={dat.id}>
                    {dat.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <div className="displayFlex">
            <div className={styles.selected}>
              <Button type="primary" onClick={assignTask}>
                Assign Rider
              </Button>
            </div>
          </div>
        </div>
        <AssigntaskTableDashboard
          loading={deliveryBoyLoading}
          assignUsers={assignUsers}
          setShipmentDetails={setShipmentDetails}
          shipmentDetails={shipmentDetails}
          data={taskData}
          setData={setTaskData}
          perPage={deliveryBoyCount.current}
          page={deliveryBoyPage}
        >
          <div style={{ marginTop: "2em" }}>
            <CustomPagination
              pageSize={perPageCount.current}
              page={deliveryBoyPage}
              setPage={(page) => {
                setDeliveryBoyPage(page);
              }}
              total={deliveryBoyCount.current}
            />
          </div>
        </AssigntaskTableDashboard>
        {error.err === true && <div></div>}
      </Modal>
      <Modal
        open={openView.open}
        onClose={() => setOpenView({ open: false, data: {} })}
        style={{
          padding: "1.4em",
          height: "fit-content",
          width: "fit-content",
        }}
      >
        <OrderDetails data={openView?.data} />
      </Modal>
    </div>
  );
}

export default CustomerOrderTable;
