import { getCookie } from "../cookie/cookies";
import { baseGetRequest, baseRequest } from "./base";

const setShipmentCharge = async (id, value) => {
  var response = await baseRequest(`/api/shipmentcharge/${id}`, "POST", { amount: value }, getCookie("login-token"));
  return response;
};

const getShipmentCharge = async (signal) => {
  var response = await baseGetRequest(`/api/shipmentcharge`, getCookie("login-token"), { signal: signal });
  return response;
};

export { setShipmentCharge, getShipmentCharge };
