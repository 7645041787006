import { Button, Radio, Select } from "antd";
import Search from "antd/lib/transfer/search";
import debounce from "lodash.debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { notifyError, notifySuccess } from "../../routes/ProtectedRoute";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import Modal from "./../../components/Modal";
import CustomPagination from "./../../components/Pagination";
import AssignTaskTable from "./../../components/Table/assignTaskTable/AssignTaskTable";
import { assignDelivery } from "./../../utils/api/delivery";
import { getAllOrders } from "./../../utils/api/orders";
import { getAllWareHouse } from "./../../utils/api/users";
import styles from "./styles.module.css";

function AssignUserForm() {
  const { Option } = Select;

  const [orderAccepted, setOrderAccepted] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [pageM, setPageM] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ o: false, data: {} });
  const [receiver, setReceiver] = useState("warehouse");
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [error, setError] = useState();

  const orderCount = useRef(1);
  const lastPageM = useRef(1);
  const perPageCount = useRef(0);

  const location = useLocation();

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

  const onReceiverChange = (e) => {
    setReceiver(e.target.value);
  };

  //function to set table data used in modal

  function handleReceiverChange(value) {
    var temp = warehouse.filter((dat, i) => dat.id === value);
    if (temp.length > 0) {
      setSelectedWarehouse(temp[0]);
    }
  }

  const assigntask = () => {
    if (receiver === "warehouse" && !selectedWarehouse) {
      setError("Please Select Warehouse");
      return;
    }
    setError(null);
    let sData = {};
    if (receiver === "warehouse") {
      sData.shipment_ids = [open.data.id];
      sData.receive_from = open.data.last_location;
      sData.destination = "warehouse";
      sData.delivery_warehouse_id = selectedWarehouse.id;
    }
    if (receiver === "receiver") {
      sData.shipment_ids = [open.data.id];
      sData.receive_from = open.data.last_location;
      sData.destination = "receiver";
    }

    assignDelivery(sData, location.state.data)
      .then((res) => {
        setOpen({ o: false, data: {} });
        setRefresh((prev) => !prev);
        notifySuccess(res.message);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };
  const getOderedData = async (signal) => {
    setLoading(true);
    await getAllOrders(signal, "assignable", pageM, search)
      .then((res) => {
        if (res.status === "Success") {
          if (pageM === 1) {
            lastPageM.current = res.data.last_page;
            orderCount.current = res.data.total;
            perPageCount.current = res.data.orders.length;
          }
          setLoading(false);
          setOrderAccepted(res.data.orders);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getOderedData(abortCont.signal);
    return () => abortCont.abort();
  }, [pageM, search, refresh]);

  useEffect(() => {
    const abortCont = new AbortController();
    getAllWareHouse(abortCont.signal)
      .then((res) => {
        setWarehouse(res.data);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>{location.state?.name}</h2>
          <p className={styles.subtitle}>{location.state?.phone}</p>
        </div>
        <div>
          <Search onChange={debouncedChangeHandler} />
        </div>
      </div>
      <div className={styles.table}>
        <AssignTaskTable
          loading={loading}
          tabledata={orderAccepted}
          open={open}
          setOpen={setOpen}
          page={pageM}
          perPageCount={perPageCount.current}
        >
          <div style={{ marginTop: "2em" }}>
            <CustomPagination
              pageSize={perPageCount.current}
              page={pageM}
              setPage={(a) => {
                setPageM(a);
              }}
              lastPage={lastPageM.current}
              total={orderCount.current}
            />
          </div>
        </AssignTaskTable>
      </div>
      <Modal
        style={{ height: "400px", width: "40%" }}
        open={open.o}
        onClose={() => {
          setOpen({ o: false, data: {} });
          setSelectedWarehouse(null);
          setError(null);
        }}
      >
        <div className={styles.pickupTitle}>Pickup Location: {open?.data?.last_location}</div>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>Please select the drop location</div>
          <div className={styles.radiobutton}>
            <Radio.Group onChange={onReceiverChange} value={receiver}>
              <Radio value={"warehouse"}>Warehouse</Radio>
              <Radio value={"receiver"}>Customer</Radio>
            </Radio.Group>
          </div>
          <div className={styles.warehouse}>
            {receiver === "warehouse" ? (
              <Select
                value={selectedWarehouse ? selectedWarehouse.id : "Please select warehouse"}
                style={{ width: "50%" }}
                onChange={handleReceiverChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {warehouse
                  .filter((value) => value.name !== open.data.last_location)
                  .map((dat, i) => (
                    <Option key={i} value={dat.id}>
                      {dat.name}
                    </Option>
                  ))}
              </Select>
            ) : null}
            {error && <div className={styles.error}>{error}</div>}
            <div className={styles.modalButton}>
              <Button type="primary" onClick={assigntask}>
                Assign task
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AssignUserForm;
