import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { notifyError, notifySuccess } from "../../routes/ProtectedRoute";
import { resetPassword } from "../../utils/api/auth";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";

function UserProfile() {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const validatePassword = (rule, value, callback) => {
    if (newPassword !== confirmPassword) {
      callback("Password must be same.");
    } else {
      callback();
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    resetPassword({ password: newPassword, old_password: oldPassword })
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess("Password Changed Successfully");
          navigate("/");
          setLoading(false);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };
  return loading ? (
    <Loading />
  ) : (
    <div>
      <Form
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onSubmit={(e) => e.preventDefault()}
      >
        <Row gutter={[, 12]}>
          <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item
              label="Current Password"
              name="Current Password"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 22 }}
              rules={[
                {
                  required: true,
                  message: `Please enter current password`,
                },
                {
                  min: 8,
                  message: "The password must be at least 8 characters.",
                },
              ]}
            >
              <Input.Password
                value={oldPassword}
                autoComplete="new-password"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="New Password"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 22 }}
              rules={[
                {
                  required: true,
                  message: `Please enter new password`,
                },
                {
                  min: 8,
                  message: "The password must be at least 8 characters.",
                },
              ]}
            >
              <Input.Password
                value={newPassword}
                autoComplete="new-password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="Confirm Password"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 22 }}
              //   validateStatus={newPassword === confirmPassword ? "success" : "error"}
              //   help={newPassword === confirmPassword ? "" : "Password must be same"}
              rules={[
                {
                  required: true,
                  message: `Please enter new password`,
                },
                {
                  min: 8,
                  message: "The password must be at least 8 characters.",
                },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                value={confirmPassword}
                autoComplete="new-password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit" size="large">
                Change Password
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default UserProfile;
