import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ViewUserTable from "../../components/Table/viewUserTable";
import { Can } from "../../utils/context/can";
import styles from "./styles.module.css";

const ViewUsers = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>User List</h2>
          <p className={styles.subtitle}>Admin can view and edit users from here</p>
        </div>
        <Can I="user-create" a="app">
          <Button type="primary" size="large" className={styles.button} onClick={() => navigate("/create/users/")}>
            Add User
          </Button>
        </Can>
      </div>
      <div className={styles.table}>
        <ViewUserTable />
      </div>
    </div>
  );
};

export default ViewUsers;
