import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../components/ImageUpload";
import { notifyError, notifySuccess } from "../../routes/ProtectedRoute";
import { getRole } from "../../utils/api/delivery";
import { updateUser } from "../../utils/api/users";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./styles.module.css";

const formData = [
  {
    label: "Name",
    name: "name",
    message: "first name",
    placeholder: "Name",
    initialValue: "name",
  },

  {
    label: "Address",
    name: "address",
    message: "address",
    placeholder: "Address",
    initialValue: "address",
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    message: "phone number",
    placeholder: "Phone Number",
    initialValue: "phone_number",
  },
  {
    label: "Email",
    name: "email",
    message: "Email",
    placeholder: "Email",
    initialValue: "email",
  },
];

const returnTypeOfUser = (role) => {
  switch (role) {
    case "Admin":
      return "admin";
    case "Customer":
      return "customer";
    case "Delivery Boy":
      return "delivery-boy";
    case "Warehouse":
      return "warehouse";

    default:
      return "admin";
  }
};

function EditDeliveryBoy() {
  const [photo, setPhoto] = useState([]);
  const [data, setData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [photoError, setPhotoError] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams();

  const handleSubmitFailed = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
  };

  const handleSubmit = (values, e) => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
    let fd = new FormData();
    fd.append("email", data.email);
    fd.append("name", data.name);
    fd.append("address", data.address);
    fd.append("mobile_number", data.phoneNumber);
    fd.append("profile_picture", photo[0].originFileObj);
    setLoading(true);
    updateUser(location.state.data.id, fd)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setLoading(false);
          navigate(`/viewusers/?type=${type}`);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  const getRolesToSelect = (signal) => {
    getRole(signal)
      .then((res) => {
        if (res.status === "Success") {
          setRoleList(res.data);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (location.state) {
      setData({
        name: location.state.data.name,
        address: location.state.data.address,
        email: location.state.data.email,
        role: returnTypeOfUser(type),
        phoneNumber: location.state.data.mobile_number,
        photo: location.state.data.profile_picture,
      });

      if (location.state.data.profile_picture) {
        const fileList = [
          {
            url: process.env.REACT_APP_SERVER_URL + location.state.data.profile_picture,
          },
        ];
        setPhoto([...fileList]);
      }
    }
  }, [location.state.data.id]);

  useEffect(() => {
    const abortCont = new AbortController();
    getRolesToSelect(abortCont.signal);
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Edit {type}</h2>
          <p className={styles.subtitle}>User can see and assign task from here</p>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onSubmit={(e) => e.preventDefault()}
      >
        <Row gutter={[, 12]}>
          {formData.map((item, index) => (
            <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} key={index}>
              {item.name === "phoneNumber" ? (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  initialValue={location.state.data.mobile_number || data[item.name]}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                    {
                      max: 10,
                      min: 9,
                      message: "Phone number must be between 9 to 10 characters",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder={item.placeholder}
                    className={styles.numberArrow}
                    value={data[item.name]}
                    onChange={(e) => {
                      const value = { ...data };
                      value[`${item.name}`] = e.target.value;
                      setData(value);
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  initialValue={location.state.data[item.initialValue] || data[item.name]}
                  wrapperCol={{ span: 22 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={item.placeholder}
                    value={data.name || item[item.name]}
                    onChange={(e) => {
                      const value = { ...data };

                      value[`${item.name}`] = e.target.value;
                      setData(value);
                    }}
                  />
                </Form.Item>
              )}
            </Col>
          ))}
        </Row>
        <Row style={{ marginTop: "12px" }}>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}></Col>
          <Col xl={{ span: 8 }} lg={{ span: 13 }} md={{ span: 24 }} sm={{ span: 24 }} style={{ marginBottom: "1em" }}>
            <ImageUpload fileList={photo} setFileList={setPhoto} />
            {photoError && <div style={{ color: "red" }}>Please upload your photo</div>}
          </Col>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit" size="large" loading={loading} disabled={loading}>
                Update {type}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditDeliveryBoy;
