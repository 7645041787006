import {
  BellOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import cx from "classnames";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/api/auth";
import { AbilityContext } from "../../utils/context/can";
import { clearCookie, getCookie } from "../../utils/cookie/cookies";
import styles from "./styles.module.css";

function Navbar({ isCollapsed, sidebarToggle }) {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);

  const menu = (
    <Menu>
      <Menu.Item>
        <div
          onClick={async () => {
            try {
            } catch (e) {
              // console.log(e);
            }

            logout()
              .then((res) => {
                clearCookie("login-token");
                clearCookie("id");
                clearCookie("password");
                clearCookie("email");
                navigate("/login/");
                ability.update([]);
              })
              .catch((err) => {
                clearCookie("login-token");
                clearCookie("id");
                clearCookie("password");
                clearCookie("email");
                navigate("/login/");
                ability.update([]);
              });
          }}
        >
          Log out
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <div className={styles.collapseicon}>
        {React.createElement(isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: styles.trigger,
          onClick: sidebarToggle,
        })}
      </div>

      <div className={styles.leftnavbar}>
        <label className={styles.welcome}>Welcome! </label>
        <label className={styles.homeservices}>HomeServices</label>
      </div>
      <div className={styles.rightnavbar}>
        <div className={styles.iconWrapper}>
          <BellOutlined className={cx(styles.icon)} onClick={() => setCurrent("notificaton")} />
          {current === "notificaton" ? <div className={styles.active}></div> : null}
        </div>
        <div className={styles.iconWrapper}>
          <SettingOutlined
            className={styles.icon}
            onClick={() => {
              navigate("/setting/");
              setCurrent("setting");
            }}
          />
          {current === "setting" ? <div className={styles.active}></div> : null}
        </div>

        <div className={styles.iconWrapper}>
          <div className="">
            <UserOutlined className={styles.icon} onClick={() => setCurrent("user")} />
          </div>

          {current === "user" ? <div className={styles.active}></div> : null}
        </div>

        <Dropdown overlay={menu} placement="bottom">
          <div className={styles.useProfileWrapper}>
            <div className={styles.useProfile}>
              <div> {getCookie("logistic-user-data")?.name}</div>
              <div className={styles.iconWrapper}>
                <DownOutlined className={styles.usericon} />
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default Navbar;
