import React from "react";
import ShowAllOrdersTable from "../../components/Table/showAllOrdersTable";
import styles from "./styles.module.css";

function ShowAllOrders() {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>All order items</h2>
          <p className={styles.subtitle}>Admin can view all ordered items</p>
        </div>
      </div>
      <div>
        <ShowAllOrdersTable />
      </div>
    </div>
  );
}

export default ShowAllOrders;
