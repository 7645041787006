import {
  BankOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  HomeOutlined,
  PlusOutlined,
  RiseOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

const menuItems = [
  {
    title: "Dashboard",
    icon: <HomeOutlined style={{ fontSize: "18px" }} />,
    path: "/",
    permissionRequired: "none",
  },

  {
    title: "Create User",
    icon: <PlusOutlined style={{ fontSize: "18px" }} />,
    path: "/create/users/",
    permissionRequired: "user-create",
  },
  {
    title: "View Users",
    icon: <UsergroupAddOutlined style={{ fontSize: "18px" }} />,
    path: "/viewusers/",
    permissionRequired: "user-list",
  },
  {
    title: "View Roles",
    path: "/viewroles/",
    icon: <BankOutlined style={{ fontSize: "18px" }} />,
    permissionRequired: "role-create",
  },
  {
    title: "Assign Rider",
    icon: <UserSwitchOutlined style={{ fontSize: "18px" }} />,
    path: "/assignuser/",
    permissionRequired: "assign-rider",
  },
  {
    title: "Show All Orders",
    icon: <UnorderedListOutlined style={{ fontSize: "18px" }} />,
    path: "/showallorders/",
    permissionRequired: "order-list",
  },
  {
    title: "Show Warehouse",
    icon: <DatabaseOutlined style={{ fontSize: "18px" }} />,
    path: "/showallwarehouses/",
    permissionRequired: "warehouse-orders-list",
  },
  {
    title: "My Warehouse",
    icon: <DatabaseOutlined style={{ fontSize: "18px" }} />,
    path: "/mywarehouse/",
    permissionRequired: "warehouse-orders-list-self",
  },
  {
    title: "Track Orders",
    icon: <RiseOutlined style={{ fontSize: "18px" }} />,
    path: "/trackorders/",
    permissionRequired: "track-order",
  },
  {
    title: "Cash on Delivery",
    icon: <DollarCircleOutlined style={{ fontSize: "18px" }} />,
    path: "/cod/",
    permissionRequired: "cod-list",
  },
  {
    title: "Shipment Charge",
    icon: <DollarCircleOutlined style={{ fontSize: "18px" }} />,
    path: "/shipmentcharge/",
    permissionRequired: "shipment-charge-update",
  },
];

export default menuItems;
