import { getCookie } from "../cookie/cookies";
import { baseGetRequest } from "./base";

const getDashboardData = async (signal, startDate, endDate) => {
  var url = `/api/dashboard/statistics?start_date=${startDate}&end_date=${endDate}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

export { getDashboardData };
