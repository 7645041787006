import React, { useEffect, useState } from "react";
import { notifyError } from "../../routes/ProtectedRoute";
import { getDashboardData } from "../../utils/api/dashboard";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { OrderTable } from "./../../components/Table";
import Header from "./Header";
import styles from "./styles.module.css";
import { svg } from "./svg";

const formatDate = (date) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  return [year, month, day].join("-");
};

function Dashboard() {
  const data = [
    {
      name: "Total Order Placed",
      type: "total",
      icon: "icon",
      color: "#39B471",
    },
    {
      name: "Total Completion",
      type: "completed",
      icon: "icon",
      color: "#3971B4",
    },

    {
      name: "Total Cancellation",
      type: "canceled",
      icon: "icon",
      color: "#B4398A",
    },
    {
      name: "Total Pending",
      type: "pending",
      icon: "icon",
      color: "#089DB1",
    },
  ];

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  const onDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const fetchDashboardData = (signal) => {
    setLoading(true);
    getDashboardData(signal, formatDate(startDate), formatDate(endDate))
      .then((res) => {
        if (res.status === "Success") {
          setLoading(false);
          setDashboardData(res.data);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchDashboardData(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [startDate, endDate]);

  return (
    <>
      <Header startDate={startDate} endDate={endDate} onDateChange={onDateChange} />
      <div className={styles.scroll}>
        <div className={styles.container}>
          <div className={styles.imageContain}>
            <img className={styles.image} src="/static/img/dashboard.png" />
          </div>
          <div className={styles.cardsContainer}>
            {data.map((dat, i) => (
              <div key={i} className={styles.card} style={{ backgroundColor: dat.color }}>
                <div className={styles.cardleft}>
                  <span>{dat.name}</span>
                  <br />
                  <span className={styles.p}>
                    {loading ? <div style={{ fontSize: "0.65rem" }}>Fetching...</div> : dashboardData[dat.type]}
                  </span>
                </div>

                <div className={styles.logo}>
                  <div className={styles.svg}>{svg}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <OrderTable />
      </div>
    </>
  );
}

export default Dashboard;
