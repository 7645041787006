import cx from "classnames";
import React from "react";
import styles from "./styles.module.css";

function index({ children, open = false, onClose, style = {} }) {
  return (
    <div
      className={cx(styles.container, open === true ? styles.open : styles.close)}
      style={open ? null : { display: "none" }}
    >
      <div className={styles.absolute} onClick={() => onClose()} />

      <div className={styles.contain} style={style}>
        <div className={styles.children}>{children}</div>

        <div onClick={() => onClose()} className={styles.cancel}>
          <div>x</div>
        </div>
      </div>
    </div>
  );
}

export default index;
