import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { notifyError, notifySuccess } from "../../routes/ProtectedRoute";
import { getShipmentCharge, setShipmentCharge } from "../../utils/api/shipmentCharge";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./styles.module.css";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);

function ShipmentCharge() {
  const [charge, setCharge] = useState();
  const [currentChargeData, setCurrentChargeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You want to update shipment charge !!",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Ok",
      denyButtonText: `Discard`,
      confirmButtonColor: `green`,
      // confirmButtonColor: `var(--button-hover)`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success").then((res1) => {
        setShipmentCharge(currentChargeData.id, charge)
          .then((res) => {
            if (res.status === "Success") {
              notifySuccess("Shipment Charge Updated Successfully");
              setLoading(false);
              setRefresh((prev) => !prev);
              // navigate("/");
            }
          })
          .catch((err) => {
            axiosErrorHandler(err, notifyError);
            setLoading(false);
          });
        // });
      }
    });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    setLoader(true);
    getShipmentCharge(abortCont.signal)
      .then((res) => {
        setCurrentChargeData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {
        setLoader(false);
      });
    return () => abortCont.abort();
  }, [refresh]);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Change Shipment Charge</h2>
          <p className={styles.subtitle}>Admin can change shipment charge from here</p>
        </div>
      </div>
      <div className={styles.charge}>
        Current Shipment Charge: Rs. {loader ? <Spin indicator={antIcon} /> : currentChargeData?.amount}
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onSubmit={(e) => e.preventDefault()}
      >
        <Row gutter={[, 12]}>
          <Col xxl={{ span: 5 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 10 }} sm={{ span: 12 }}>
            <Form.Item
              label="Change shipment charge"
              name="charge"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `Please enter shipment charge`,
                },
              ]}
            >
              <Input
                placeholder="Shipment Charge"
                prefix={<div>Rs.</div>}
                type="number"
                value={charge}
                onChange={(e) => {
                  setCharge(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit" size="large" loading={loading} disabled={loading}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ShipmentCharge;
