import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Input, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { notifyError } from "../../routes/ProtectedRoute";
import { getAllOrders } from "../../utils/api/orders";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { assignDelivery, getDeliveryBoys } from "./../../utils/api/delivery";
import Modal from "./../Modal/";
import AssigntaskTableDashboard from "./assignTaskDashboard/AssignTaskTableDashboard";
import styles from "./OrderTable.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "Ongoing":
      return <SyncOutlined spin />;

    case "Cancelled":
      return <CloseCircleOutlined />;

    case "new":
      return <ClockCircleOutlined />;

    case "Delivered":
      return <CheckCircleOutlined />;

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case "Ongoing":
      return "blue";

    case "Cancelled":
      return "red";

    case "new":
      return "gold";

    case "Order Accepted":
      return "green";

    default:
      return "green";
  }
};

function OrderTable() {
  const { Search } = Input;
  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Shipments Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Pick Location",
      dataIndex: "pick_location",
      key: "pick_location",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Dropoff Location",
      dataIndex: "drop_location",
      key: "drop_location",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Receiver Contact",
      dataIndex: "receiver_number",
      key: "receiver_number",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Status",
      key: "shipment_status",
      dataIndex: "shipment_status",
      render: (tags) => (
        <Tag
          color={tagChooser(tags)}
          icon={iconChooser(tags)}
          style={{ textTransform: "capitalize" }}
        >
          {tags}
        </Tag>
      ),
    },
  ];

  //states
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [assignUsers, setAssignUser] = useState([]);
  const [orderAccepted, setOrderAccepted] = useState({ data: [] });

  ///api callls
  useEffect(() => {
    const abortCont = new AbortController();
    fetchAllDeliveryBoys(abortCont.signal);
    fetchAllOrders(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [open, refresh]);

  const fetchAllDeliveryBoys = (signal) => {
    setLoading(true);
    getDeliveryBoys("all", page, "", signal)
      .then((res) => {
        if (res.status === "Success") {
          setAssignUser(res.data.delivery_boys);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  const fetchAllOrders = (signal) => {
    setLoading(true);
    getAllOrders(signal, "all")
      .then((res) => {
        if (res.status === "Success") {
          setLoading(false);
          setOrderAccepted(res.data);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    setData(assignUsers.map((dat) => ({ ...dat, is_selected: false })));
  }, [open]);

  const assignTask = () => {
    const istrue = data.every((dat) => dat.is_selected === false);
    if (istrue) {
      return;
    }
    const temp = data.filter((dat) => dat.is_selected === true);

    assignDelivery(shipmentDetails, temp[0].user_id)
      .then((res) => {
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>Order Table</div>
        <div>
          <MoreOutlined className={styles.icon} />
        </div>
      </div>
      <Table
        className={styles.table}
        loading={loading}
        style={{ cursor: "pointer" }}
        columns={columns}
        dataSource={orderAccepted.orders?.map((dat, i) => ({
          ...dat,
          sn: i + 1,
          key: dat.shipments_unique_id,
        }))}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
        expandable={{
          expandedRowRender: (shipmentData) => (
            <p style={{ margin: 0 }}>
              <table>
                <tr className="ta">
                  <th>Item</th>
                  <th>Item Name</th>
                  <th>Item Price</th>
                  <th>Item Quantity</th>
                </tr>
                {shipmentData.shipmentitems?.map((item, index) => (
                  <tr className="ta" key={index}>
                    <td>{index + 1}</td>
                    <td>{item.item_name}</td>
                    <td>{item.item_price}</td>

                    <td>{item.item_quantity}</td>
                  </tr>
                ))}
              </table>
            </p>
          ),
          rowExpandable: (shipmentData) =>
            shipmentData.name !== "Not Expandable",
        }}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={styles.heading}>
          <div>
            <Search placeholder="Search Product" />
          </div>
          <div className="displayFlex">
            <div className={styles.selected}>
              <Button type="primary" onClick={assignTask}>
                Assign Rider
              </Button>
            </div>
          </div>
        </div>
        <AssigntaskTableDashboard
          assignUsers={assignUsers}
          setShipmentDetails={setShipmentDetails}
          shipmentDetails={shipmentDetails}
          data={data}
          setData={setData}
        />
      </Modal>
    </div>
  );
}

export default OrderTable;
