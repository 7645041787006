import "antd/dist/antd.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import ability from "./config/acl/ability";
import { AbilityContext } from "./utils/context/can";

ReactDOM.render(
  <React.StrictMode>
    <AbilityContext.Provider value={ability}>
      <App />
    </AbilityContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
