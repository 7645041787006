import { Button, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PendingTable from "../../components/Table/deliveryBoy/PendingTable";
import { notifyError } from "../../routes/ProtectedRoute";
import { Can } from "../../utils/context/can";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import CustomPagination from "./../../components/Pagination";
import { getDeliveryProfileData } from "./../../utils/api/delivery";
import { getDeliveryboyProfile } from "./../../utils/api/users";
import styles from "./styles.module.css";

const DeliveryBoyProfile = () => {
  const { Search } = Input;
  const tab = ["pending", "delivered", "cancelled"];

  const [selectedTab, setSelectedTab] = useState("pending");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);

  const total = useRef(1);
  const lastPage = useRef(1);
  const perPage = useRef(1);

  const location = useLocation();
  const navigate = useNavigate();

  const getImageUrl = (img) => {
    return process.env.REACT_APP_SERVER_URL + img;
  };

  const getData = async (signal) => {
    setLoading(true);
    getDeliveryProfileData(location.state.data, selectedTab, page, signal)
      .then((res) => {
        if (page === 1) {
          lastPage.current = res.data.last_page;
          total.current = res.data.total;
          perPage.current = res.data.deliveries.length;
        }
        setData(res.data.deliveries);
        setLoading(false);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getData(abortCont.signal);
    return () => abortCont.abort();
  }, [selectedTab, page, refresh]);

  useEffect(() => {
    const abortCont = new AbortController();
    getDeliveryboyProfile(location.state.data, abortCont.signal)
      .then((res) => {
        setUserProfile(res.data);
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.profileWrapper}>
        <div className={styles.leftProfile}>
          <div className={styles.topProfile}>
            <div className={styles.profileImage}>
              <img
                src={getImageUrl(userProfile?.user?.profile_picture)}
                className={styles.image}
              ></img>
            </div>

            <div className={styles.profileDetails}>
              <div className={styles.name}>{userProfile?.user?.name}</div>
              <div className={styles.profileType}>
                {userProfile?.user?.mobile_number}
              </div>
              <div className={styles.profileStats}>
                <div>
                  <span className={styles.deliveredno}>
                    {userProfile?.pending}
                  </span>
                  <br />
                  <span className={styles.pendingText}> Pending</span>
                </div>
                <div>
                  <span className={styles.deliveredno}>
                    {userProfile?.delivered}
                  </span>{" "}
                  <br />
                  <span className={styles.pendingText}> Delivered</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.tabContainer}>
            {tab.map((dat, i) => (
              <div
                style={dat === selectedTab ? { color: "#009EF7" } : null}
                onClick={() => {
                  setPage(1);
                  setSelectedTab(dat);
                }}
              >
                {dat} {dat === selectedTab && <div className={styles.active} />}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightProfile}>
          <div>
            <Can I="delivery-assign">
              <Button
                type="primary"
                onClick={() => {
                  navigate("/assignuser/assignuserform", {
                    state: {
                      data: location.state.data,
                      name: location.state.name,
                      email: location.state.email,
                    },
                  });
                }}
              >
                Assign Task
              </Button>
            </Can>
            <Button className={styles.trackUser}>Track User</Button>
          </div>
          <div>
            <Search
              placeholder="input search text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <PendingTable
          data={data}
          page={page}
          perPage={perPage.current}
          setRefresh={setRefresh}
          selectedTab={selectedTab}
          loading={loading}
        />
        <CustomPagination
          pageSize={perPage.current}
          page={page}
          setPage={setPage}
          lastPage={lastPage.current}
          total={total.current}
        />
      </div>
    </div>
  );
};

export default DeliveryBoyProfile;
