import { Button, Input, Select, Table } from "antd";
import { Option } from "antd/lib/mentions";
import cx from "classnames";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { notifyError } from "../../../routes/ProtectedRoute";
import { getDeliveryBoys } from "../../../utils/api/delivery";
import { getAllOrders } from "../../../utils/api/orders";
import { Can } from "../../../utils/context/can";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import CustomPagination from "../../Pagination";
import styles from "./AssignUserTable.module.css";

function AssignUserTable() {
  const { Search } = Input;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deliveryBoyId, setDeliveryBoyId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const deliveryBoyCount = useRef(1);
  const lastPage = useRef(1);
  const perPageCount = useRef(1);

  const { width } = useWindowDimensions();

  const [selectedTab, setSelectedTab] = useState("All Riders");
  const [tabledata, setTableData] = useState([]);

  const navItemsConverter = (item) => {
    switch (item) {
      case "All Riders":
        return "all";

      case "On Way Riders":
        return "assigned";

      case "Free Riders":
        return "free";

      default:
        return "all";
    }
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      render: (text, dat) => (
        <div
          onClick={() => {
            navigate("/deliveryboyprofile/", {
              state: {
                data: dat.user_id,
                name: dat.name,
                email: dat.email,
              },
            });
          }}
          className={styles.nameColumn}
        >
          <div>
            <img
              className={styles.image}
              src={
                dat.profile_picture
                  ? process.env.REACT_APP_SERVER_URL + dat.profile_picture
                  : `https://ui-avatars.com/api/?name=${dat.name}&background=abcdef`
              }
            />
          </div>
          <div className={styles.nameWrapper}>
            <div className={styles.name}>{dat.name}</div>
            <div className={styles.profileDescription}>{dat.email}</div>
          </div>
        </div>
      ),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
      responsive: ["xl"],
      render: (text, item) => (
        <span
          onClick={() =>
            // navigate("/deliveryboyprofile/", { state: { state: "1" } })
            navigate("/deliveryboyprofile/", {
              state: {
                data: item.user_id,
                name: item.name,
                email: item.email,
              },
            })
          }
        >
          {text || "No email "}
        </span>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 100,
      render: (text, item) => (
        <div
          onClick={() => {
            navigate("/deliveryboyprofile/", {
              state: {
                data: item.user_id,
                name: item.name,
                email: item.email,
              },
            });
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 100,
      render: (text, item) => (
        <div
          onClick={() => {
            navigate("/deliveryboyprofile/", {
              state: {
                data: item.user_id,
                name: item.name,
                email: item.email,
              },
            });
          }}
        >
          {text}
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text, item) => (
        <div id="blue" className={styles.df}>
          <Can I="delivery-assign" a="app">
            <Button
              type="primary"
              className={styles.button}
              onClick={() => {
                navigate("/assignuser/assignuserform", {
                  state: {
                    data: item.user_id,
                    name: item.name,
                    email: item.email,
                  },
                });
              }}
            >
              Assign Task
            </Button>
          </Can>
          <Button className={styles.button}>Track User</Button>
        </div>
      ),
    },
  ];

  const [assignUsers, setAssignUser] = useState([]);
  const [orderAccepted, setOrderAccepted] = useState([]);
  const [search, setSearch] = useState("");
  const [deliveryBoyPage, setDeliveryBoyPage] = useState(1);
  const orderCount = useRef(1);
  const lastdeliveryBoyPage = useRef(1);
  const perDeliveryBoyPageCount = useRef(1);

  const handleSelect = (value) => {
    setSelectedTab(value);
    setDeliveryBoyPage(1);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

  const getTableData = () => {
    setTableData(orderAccepted?.map((item) => ({ ...item, is_selected: false })));
  };

  const getOderedData = async (signal) => {
    setLoading(true);
    await getAllOrders(signal, "new", page)
      .then((res) => {
        if (res?.status === "Success") {
          if (page === 1) {
            lastPage.current = res.data.last_page;
            orderCount.current = res.data.total;
            perPageCount.current = res.data.orders.length;
          }
          setLoading(false);
          setOrderAccepted(res.data.orders);
        }
        getTableData();
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  const getUsersData = async (signal) => {
    setLoading(true);
    await getDeliveryBoys(navItemsConverter(selectedTab), deliveryBoyPage, search, signal)
      .then((res) => {
        if (res?.status === "Success") {
          if (deliveryBoyPage === 1) {
            lastdeliveryBoyPage.current = res.data.last_page;
            deliveryBoyCount.current = res.data.total;
            perDeliveryBoyPageCount.current = res.data.delivery_boys.length;
          }
          setAssignUser(res.data.delivery_boys);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getOderedData(abortCont.signal);
    getUsersData(abortCont.signal);
    return () => abortCont.abort();
  }, [deliveryBoyPage, selectedTab, search]);

  useEffect(() => {
    getTableData();
  }, [open]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className="displayFlex">
          {["All Riders", "On Way Riders", "Free Riders"].map((dat, i) => (
            <div
              key={i}
              onClick={() => {
                setDeliveryBoyPage(1);
                setSelectedTab(dat);
              }}
              className={cx(styles.tabButton, dat === selectedTab ? styles.selected : null)}
            >
              {dat}
            </div>
          ))}
        </div>
        <Select style={{ width: 150 }} onChange={handleSelect} defaultValue="All Riders" className={styles.tabSelector}>
          {["All Riders", "On Way Riders", "Free Riders"].map((item, index) => (
            <Option value={item} index={index}>
              {item}
            </Option>
          ))}
        </Select>
        <div>
          <Search placeholder="Search User" onChange={debouncedChangeHandler} />
        </div>
      </div>
      <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={assignUsers.map((dat, i) => {
          return {
            ...dat,
            sn: (deliveryBoyPage - 1) * perDeliveryBoyPageCount.current + i + 1,
            key: i,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />

      <CustomPagination
        pageSize={perDeliveryBoyPageCount.current}
        page={deliveryBoyPage}
        setPage={setDeliveryBoyPage}
        lastPage={lastdeliveryBoyPage.current}
        total={deliveryBoyCount.current}
      />
    </div>
  );
}

export default AssignUserTable;
