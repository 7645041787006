import { FileImageOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";

//Converting bytes into base64
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function ImageUpload({ fileList, setFileList, title = "Choose your photo", maxUpload = 1 }) {
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  //Setting new image when uploading new image
  const onChange = ({ fileList: newFileList }) => {
    setFileList([...newFileList]);
  };

  //Preview in modal
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <div>
      <Upload.Dragger
        action="http://localhost:3000/"
        listType="picture"
        fileList={fileList}
        onChange={onChange}
        onPreview={handlePreview}
        beforeUpload={(file) => {
          return false;
        }}
        maxCount={maxUpload}
      >
        <div className={styles.imageContainer}>
          <FileImageOutlined style={{ fontSize: "2rem" }} />
          <div className={styles.titleContainer}>
            <h1 className={styles.imageHeading}>{title}</h1>
            <p className={styles.subHeading}>Drag and Drop or Choose Photo</p>
          </div>
        </div>
      </Upload.Dragger>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default ImageUpload;
