import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../routes/ProtectedRoute";
import { AbilityContext } from "../../utils/context/can";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { getRoleById, setRoleById } from "./../../utils/api/role";
import styles from "./styles.module.css";

const formData = [
  { label: "Title", name: "title", message: "Title", placeholder: "Title" },
];

function EditRole() {
  const [permisions, setPermisions] = useState([]);
  const [roleData, setRoleData] = useState();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const ability = useContext(AbilityContext);

  const handleSubmit = () => {
    var data = permisions.filter(
      (permission, index) => permission.has_permission === 1
    );
    data = data.map((permission, index) => permission.name);
    const roleInfo = { name: title, permission: [...data] };
    setRoleById(location.state.data.id, roleInfo)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          let abilities = res.data.user_permissions.map((e) => ({
            action: e,
            subject: "app",
          }));
          abilities.push({ action: "none", subject: "app" });
          ability.update(abilities);
          setLoading(false);
          navigate("/viewroles/");
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  const getRoleData = (signal) => {
    setLoading(true);
    getRoleById(location.state.data.id, signal)
      .then((res) => {
        if (res.status === "Success") {
          setRoleData(res.data);
          setPermisions(res.data.role_permissions);
          setTitle(res.data.role.name);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getRoleData(abortCont.signal);

    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Add Role</h2>
          <p className={styles.subtitle}>Admin can add warehouse from here</p>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[, 12]}>
          {formData.map((item, index) => (
            <Col
              key={index}
              xxl={{ span: 8 }}
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              {
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  initialValue={location.state.data.name || title}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={item.placeholder}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>
              }
            </Col>
          ))}
        </Row>

        <p style={{ marginBottom: "0.5em" }}>Permission</p>

        <Row>
          {permisions?.map((item, index) => (
            <Col span={8} key={index}>
              <Checkbox
                checked={item?.has_permission === 1}
                onChange={(e) => {
                  const data = [...permisions];
                  data[index].has_permission = e.target.checked ? 1 : 0;
                  setPermisions(data);
                }}
              >
                {item.name}
              </Checkbox>
            </Col>
          ))}
        </Row>

        <Row style={{ marginTop: "1.5em" }}>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button
                onClick={handleSubmit}
                type="primary"
                htmlType="submit"
                size="large"
                disabled={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditRole;
