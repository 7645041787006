import { getCookie } from "../cookie/cookies";
import { baseGetRequest, baseRequest } from "./base";

const createDeliveryBoy = async (data) => {
  var response = await baseRequest(`/api/register`, "POST", data, getCookie("login-token"));
  return response;
};

const getRole = async (signal) => {
  var response = await baseGetRequest(`/api/roles`, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const completeOrder = async (id) => {
  var url = `/api/delivery/complete/`;
  var response = await baseRequest(url + id, "POST", {}, getCookie("login-token"));
  return response;
};

const assignDelivery = async (data, id) => {
  var response = await baseRequest(
    "/api/delivery-boy/" + id + "/assign-delivery",
    "POST",
    data,
    getCookie("login-token")
  );
  return response;
};

const cancelDelivery = async (id) => {
  var url = `/api/delivery/cancelled/`;
  var response = await baseRequest(url + id, "POST", {}, getCookie("login-token"));
  return response;
};

function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

const getDeliveryBoys = async (status, page, search = "", signal) => {
  let url;
  /^-?[\d.]+(?:e-?\d+)?$/.test(search)
    ? (url = `/api/delivery-boys?status=${status}&page=${page}&mobile_number=${search}`)
    : (url = `/api/delivery-boys?status=${status}&page=${page}&name=${search}`);
  // var url = `/api/delivery-boy/${status}?page=${page}&name=${search}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const getDeliveryProfileData = async (id, status, page, signal) => {
  var response = await baseGetRequest(
    "/api/delivery-boy/" + id + "/deliveries?status=" + status + "&page=" + page,
    getCookie("login-token"),
    { signal: signal }
  );
  return response;
};

export {
  createDeliveryBoy,
  getRole,
  assignDelivery,
  getDeliveryBoys,
  getDeliveryProfileData,
  cancelDelivery,
  completeOrder,
};
