import { getCookie } from "../cookie/cookies";
import { baseGetRequest, baseRequest } from "./base";

const getUsers = async (role, page, search = "", signal) => {
  let url;
  /^-?[\d.]+(?:e-?\d+)?$/.test(search)
    ? (url = `api/all/users/${role}?page=${page}&mobile=${search}`)
    : (url = `api/all/users/${role}?page=${page}&name=${search}`);
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};
const getAllWareHouse = async (signal) => {
  var url = `api/showallwarehouse`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const updateUser = async (id, data) => {
  var url = `api/user/update/${id}`;

  var response = await baseRequest(url, "POST", data, getCookie("login-token"));
  return response;
};

const getDeliveryboyProfile = async (id, signal) => {
  var url = "api/delivery-boy/" + id;

  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const getCustomerOrders = async (id, status, page, signal) => {
  var url = `api/user/${id}/shipments?order_status=${status}&page=${page}`;
  var response = await baseGetRequest(url, getCookie("login-token"), {
    signal: signal,
  });
  return response;
};

const updateUserStatus = async (id) => {
  var url = `api/user/${id}/status`;
  var response = await baseRequest(url, "POST", getCookie("login-token"));
  return response;
};

export { getUsers, getAllWareHouse, getDeliveryboyProfile, updateUser, getCustomerOrders, updateUserStatus };
