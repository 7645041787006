import React from "react";
import ShowAllWarehousesTable from "../../components/Table/showAllWarehousesTable";
import ShowMyWarehouseTable from "../../components/Table/showMyWarehouseTable";
import styles from "./styles.module.css";

function ShowMyWarehouse() {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>All order items</h2>
          <p className={styles.subtitle}>
            Admin can view all ordered items in warehouse
          </p>
        </div>
      </div>
      <div>
        <ShowMyWarehouseTable />
      </div>
    </div>
  );
}

export default ShowMyWarehouse;
