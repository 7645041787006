import { Layout } from "antd";
import { useContext, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { onMessageListener } from "../firebase";
import NotFound from "../pages/notFound";
import { AbilityContext } from "../utils/context/can";
import { getCookie } from "../utils/cookie/cookies";
import protectedRoutes from "./protectedRoutes";

export const notifySuccess = (message) => toast.success(message);
export const notifyError = (message) => toast.error(message);

function ProtectedRoute() {
  const { Header, Content, Sider } = Layout;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);

  const sidebarToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  const ability = useContext(AbilityContext);

  onMessageListener()
    .then((payload) => {
      notifySuccess(payload.notification.body);
    })
    .catch((err) => console.log("failed: ", err));

  return getCookie("login-token") ? (
    loading ? (
      <Loading />
    ) : (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Layout className="main-layout">
          <Sider
            width={250}
            theme="dark"
            trigger={null}
            collapsible
            collapsed={isCollapsed}
          >
            <Sidebar isCollapsed={isCollapsed} />
          </Sider>
          <Layout className="main-wrapper">
            <Header
              style={{
                backgroundColor: "white",
                padding: "0",
                height: "fit-content",
              }}
            >
              <Navbar isCollapsed={isCollapsed} sidebarToggle={sidebarToggle} />
            </Header>
            <Content
              style={{
                margin: "1.125em 1em",
                padding: "1em",
                minHeight: 280,
              }}
            >
              <Routes>
                {protectedRoutes.map((route, index) => {
                  if (ability.can(route.permissionRequired, "app")) {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  }
                })}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </>
    )
  ) : (
    <Navigate to="/login/" />
  );
}
export default ProtectedRoute;
