export const getDateTime = (data) => {
  const today = new Date(data);
  const year = today.toLocaleDateString("en", {
    year: "numeric",
  });
  const date = `${today.getDate()} ${today.toLocaleDateString("en", {
    month: "long",
  })}\n\n, ${year}`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }, `;

  const time = today.toLocaleTimeString("en", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};
