import { Button, Table } from "antd";
import React, { useState } from "react";
import Modal from "../../Modal";
import ShowAllOrderModalDetails from "../../ShowAllOrderModalDetails";
import styles from "./index.module.css";

{
  /*  */
}

function AssignTaskTable({ tabledata, open, setOpen, loading, children, page, perPageCount }) {
  const columns = [
    { title: "S.N.", key: "sn", dataIndex: "sn", render: (text) => <span>{text}</span> },
    {
      title: "Shipping Id",
      key: "shipments_unique_id",
      dataIndex: "shipments_unique_id",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Pickup Address",
      dataIndex: "pick_location",
      key: "address",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Dropping Address",
      dataIndex: "drop_location",
      key: "address",
      responsive: ["md"],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Receiver Number",
      key: "receiver_number",
      dataIndex: "receiver_number",
      responsive: ["md"],
      render: (text, item) => <span>{text}</span>,
    },
    {
      title: "Receiver Name",
      key: "receiver_name",
      dataIndex: "receiver_name",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "action",
      responsive: ["md"],
      render: (text, item) => (
        <div id="blue" className={styles.df}>
          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              setItem(item);
              setOpenModal(true);
            }}
          >
            View
          </Button>
          <Button type="primary" className={styles.button} onClick={(dat) => setOpen({ ...open, o: true, data: item })}>
            Assign
          </Button>
        </div>
      ),
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState({});

  const columnData = () => {
    return (
      tabledata?.map((item, i) => ({
        ...item,
        key: item.shipments_unique_id,
        sn: (page - 1) * perPageCount + i + 1,
      })) || []
    );
  };

  return (
    <div className={styles.container}>
      <Table
        loading={loading}
        className={styles.table}
        columns={columns}
        dataSource={columnData()}
        pagination={false}
        style={{ cursor: "pointer" }}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{
          padding: "1.4em",
          height: "fit-content",
          width: "fit-content",
          padding: "0 0 0.5em 0",
        }}
      >
        <ShowAllOrderModalDetails data={item} />
      </Modal>
      {children}
    </div>
  );
}

export default AssignTaskTable;
