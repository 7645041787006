import { Checkbox, Table } from "antd";
import React from "react";
import styles from "./index.module.css";

const DashboardTable = (props) => {
  const columns = [
    {
      title: "",
      key: "is_selected",
      dataIndex: "is_selected",
      responsive: ["md"],
      render: (text, item, index) => (
        <span>
          <Checkbox
            checked={text}
            onChange={(e) => {
              const temp = [...props.assignUsers];
              const temp2 = temp.map((dat) => ({ ...dat, is_selected: false }));
              temp2[index].is_selected = !temp2[index].is_selected;

              props.setData(temp2);
            }}
          />
        </span>
      ),
    },
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      render: (text, a, i) => <span>{text}</span>,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, a, i) => <span>{text}</span>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, a, i) => <span>{text}</span>,
    },

    {
      title: "Contact",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (text, a, i) => <span>{text}</span>,
    },
  ];

  return (
    <div className={styles.container}>
      <Table
        loading={props.loading}
        className={styles.table}
        columns={columns}
        dataSource={props.data?.map((dat, i) => {
          return {
            ...dat,
            sn: (props.page - 1) * props.perPage + i + 1,
            key: i,
          };
        })}
        pagination={false}
        style={{ cursor: "pointer" }}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
      {props.children}
    </div>
  );
};

export default DashboardTable;
