import React from "react";
import AssignUserTable from "../../components/Table/assignUser/AssignUserTable";
import styles from "./styles.module.css";

const AssignUser = () => {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Riders List</h2>
          <p className={styles.subtitle}>Admin can view rider list and assign task from here</p>
        </div>
      </div>
      <div className={styles.table}>
        <AssignUserTable />
      </div>
    </div>
  );
};

export default AssignUser;
