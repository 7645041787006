import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { notifySuccess } from "../../../routes/ProtectedRoute";
import { cancelOrder } from "../../../utils/api/orders";

function CancelModal({ openCancelModal, setOpenCancelModal, cancelItem, setRefresh }) {
  const [message, setMessage] = useState();
  const [error, setError] = useState("");
  const handleCancel = () => {
    setOpenCancelModal(false);
    setMessage(null);
    setError(null);
  };

  const handleOk = (cancelItem) => {
    if (message === "") {
      setError("Please Enter Cancellation Message");
      return;
    }
    cancelOrder(cancelItem, {
      cancelation_message: message,
    }).then((res) => {
      if (res.status === "Success") {
        setRefresh((prev) => !prev);
        notifySuccess(res.message);
      }
    });
    setMessage(null);
    setError(null);
    setOpenCancelModal(false);
  };
  return (
    <div>
      <Modal
        title="Are you sure you want to cancel the order?"
        visible={openCancelModal}
        onOk={() => handleOk(cancelItem)}
        onCancel={handleCancel}
      >
        <TextArea
          rows={4}
          placeholder="Enter cancellation message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {error && <div style={{ color: "red", fontSize: ".85rem" }}>{error}</div>}
      </Modal>
    </div>
  );
}

export default CancelModal;
