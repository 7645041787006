import axiosInstance from "./axiosInstance";

const baseRequest = async (url, method, body, token = null, options) => {
  let response = {};
  if (method === "POST") {
    response = await axiosInstance(token, options).post(url, body);
  } else if (method === "PUT") {
    response = await axiosInstance(token, options).put(url, body);
  } else if (method === "DELETE") {
    response = await axiosInstance(token, options).delete(url);
  }

  return response.data;
};

const baseGetRequest = async (url, token = null, options) => {
  const response = await axiosInstance(token, options).get(url);
  return response.data;
};

export { baseRequest, baseGetRequest };
