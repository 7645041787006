import AssignUser from "../pages/assignUser";
import AssignUserForm from "../pages/assignuserForm";
import CashOnDelivery from "../pages/cashOnDelivery";
import CustomerProfile from "../pages/customerProfile";
import Dashboard from "../pages/dashboard";
import DeliveryBoy from "../pages/deliveryBoy";
import DeliveryBoyProfile from "../pages/deliveryBoyProfile";
import EditDeliveryBoy from "../pages/editDeliverBoy";
import EditRole from "../pages/editRole";
import MarkComplete from "../pages/markComplete";
import Role from "../pages/role";
import ShipmentCharge from "../pages/shipmentCharge";
import ShowAllOrders from "../pages/showAllOrders";
import ShowAllWarehouses from "../pages/showAllWarehouses";
import ShowMyWarehouse from "../pages/showMyWarehouse";
import TrackOrders from "../pages/trackOrders";
import UserProfile from "../pages/userProfile";
import ViewRoles from "../pages/viewRoles";
import ViewUsers from "../pages/viewUsers";
import Warehouse from "../pages/warehouse";

const routes = [
  { path: "/", element: <Dashboard />, permissionRequired: "none" },
  { path: "/setting/", element: <UserProfile />, permissionRequired: "none" },
  {
    path: "/assignuser/",
    element: <AssignUser />,
    permissionRequired: "assign-rider",
  },
  {
    path: "/viewusers/",
    element: <ViewUsers />,
    permissionRequired: "user-list",
  },
  {
    path: "/assignuser/assignuserform/",
    element: <AssignUserForm />,
    permissionRequired: "delivery-assign",
  },
  {
    path: "/deliveryboyprofile/",
    element: <DeliveryBoyProfile />,
    permissionRequired: "delivery-boy-list",
  },
  {
    path: "/customerprofile/",
    element: <CustomerProfile />,
    permissionRequired: "customer-list",
  },
  {
    path: "/create/users/",
    element: <DeliveryBoy />,
    permissionRequired: "user-create",
  },
  {
    path: "/update/:type/",
    element: <EditDeliveryBoy />,
    permissionRequired: "user-edit",
  },
  {
    path: "/create/warehouse/",
    element: <Warehouse />,
    permissionRequired: "warehouse-create",
  },
  {
    path: "/showallwarehouses/",
    element: <ShowAllWarehouses />,
    permissionRequired: "warehouse-orders-list",
  },
  {
    path: "/mywarehouse/",
    element: <ShowMyWarehouse />,
    permissionRequired: "warehouse-orders-list-self",
  },
  { path: "/create/role/", element: <Role />, permissionRequired: "none" },
  {
    path: "/role/update/",
    element: <EditRole />,
    permissionRequired: "role-edit",
  },
  {
    path: "/viewroles/",
    element: <ViewRoles />,
    permissionRequired: "role-list",
  },
  {
    path: "/showallorders/",
    element: <ShowAllOrders />,
    permissionRequired: "order-list",
  },
  {
    path: "/markcomplete/",
    element: <MarkComplete />,
    permissionRequired: "delivey-complete",
  },
  {
    path: "/trackorders/",
    element: <TrackOrders />,
    permissionRequired: "user-list",
  },
  {
    path: "/cod/",
    element: <CashOnDelivery />,
    permissionRequired: "cod-list",
  },
  {
    path: "/shipmentcharge/",
    element: <ShipmentCharge />,
    permissionRequired: "shipment-charge-update",
  },
];

export default routes;
