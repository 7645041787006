import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { forgotPassword } from "../../utils/api/auth";
import styles from "./styles.module.css";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailError(false);
    if (email === "") {
      setEmailError(true);
      return;
    }

    if (validateEmail(email)) {
      forgotPassword({ email: email }).then((res) => {
        if (res.status === "success") {
          // console.log("success");
        }
      });
    }
  };
  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>Reset password</h1>
          <p className={styles.subtitle}>Enter your email address to reset your password</p>
        </div>

        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onSubmitCapture={handleSubmit}
        >
          <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email!" }]}>
            <Input type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
          </Form.Item>

          <div className={styles.buttonContainer}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
