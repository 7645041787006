import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Input, Table, Tag } from "antd";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../routes/ProtectedRoute";
import { completeOrder, getDeliveryBoys } from "../../../utils/api/delivery";
import { getAllOrders } from "../../../utils/api/orders";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import Modal from "../../Modal";
import CustomPagination from "../../Pagination";
import ShowAllOrderModalDetails from "../../ShowAllOrderModalDetails";
import styles from "./markCompleteTable.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case "not-assigned":
      return <CloseCircleOutlined />;

    case "cancelled":
      return <CloseCircleOutlined />;

    case "new":
      return <ClockCircleOutlined />;

    case "completed":
      return <CheckCircleOutlined />;

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return "blue";

    case "not-assigned":
      return "purple";

    case "cancelled":
      return "red";

    case "new":
      return "gold";

    case "completed":
      return "green";

    default:
      return "green";
  }
};

function MarkCompleteTable() {
  const { Search } = Input;
  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      responsive: ["md"],
    },
    {
      title: "Shipment Id",
      dataIndex: "shipments_unique_id",
      key: "shipments_unique_id",
      responsive: ["md"],
    },
    {
      title: "Pickup Location",
      dataIndex: "pickup",
      key: "pickup",
      responsive: ["md"],
    },
    {
      title: "Dropoff Location",
      dataIndex: "dropoff",
      key: "dropoff",
      responsive: ["md"],
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      responsive: ["md"],
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status, index) => (
        <Tag key={index} color={tagChooser(status)} icon={iconChooser(status)} style={{ textTransform: "capitalize" }}>
          {status}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, item, id) => (
        <div id="blue" className={styles.df}>
          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              handleComplete(item.index);
            }}
          >
            Complete
          </Button>

          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              setOpenView({ ...openView, open: true, data: item });
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];
  const [trigger, setTrigger] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [page, setPage] = useState(1);
  const shipmentCount = useRef(1);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Assigned Orders");
  const navigate = useNavigate();
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [assignUsers, setAssignUser] = useState([]);
  const [orderAccepted, setOrderAccepted] = useState({ data: [] });
  const [data, setData] = useState();
  const [openView, setOpenView] = useState({ open: false, data: {} });
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const perPageCount = useRef(1);

  const handleComplete = (id) => {
    completeOrder(id).then((res) => {
      if (res.status === "Success") {
        setRefresh((prev) => !prev);
        notifySuccess(res.message);
      }
    });
  };

  const navItemsConverter = (item) => {
    switch (item) {
      case "All Orders":
        return "all";

      case "New Orders":
        return "new";

      case "Not Assigned Orders":
        return "not-assigned";

      case "Assigned Orders":
        return "assigned";

      case "Completed Orders":
        return "completed";

      case "Cancelled Orders":
        return "cancelled";

      default:
        return "all";
    }
  };

  const fetchOrderItems = async (signal) => {
    setLoading(true);
    await getAllOrders(signal, navItemsConverter(selectedTab), page, search)
      .then((res) => {
        if (res.status === "Success") {
          if (page === 1) {
            shipmentCount.current = res.data.total;
            perPageCount.current = res.data.orders.length;
            setLoading(false);
          }

          setOrderItems(res.data.orders);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  const fetchAllDeliveryBoys = (signal) => {
    setLoading(true);
    getDeliveryBoys("all", page, "", signal)
      .then((res) => {
        if (res.status === "Success") {
          setAssignUser(res.data.delivery_boys);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchOrderItems(abortCont.signal);
    return () => abortCont.abort();
  }, [trigger, page, selectedTab, refresh, search]);

  useEffect(() => {
    const abortCont = new AbortController();
    fetchAllDeliveryBoys(abortCont.signal);
    return () => abortCont.abort();
  }, [open, refresh]);

  useEffect(() => {
    setData(assignUsers.map((dat) => ({ ...dat, is_selected: false })));
  }, [open]);

  const columnData = () => {
    return orderItems?.map((item, i) => ({
      ...item,
      pickup: item.pick_location,
      dropoff: item.drop_location,
      status: item.shipment_status,
      contact: item.receiver_number,
      key: item.shipments_unique_id,
      index: item.id,
      sn: (page - 1) * perPageCount.current + i + 1,
    }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className="displayFlex">
          {["Assigned Orders", "Completed Orders"].map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setPage(1);
                setSelectedTab(item);
              }}
              className={cx(styles.button, item === selectedTab ? styles.selected : null)}
            >
              {item}
            </div>
          ))}
        </div>

        <div>
          <Search
            placeholder="Search items"
            value={search}
            onChange={(e) => {
              const a = e.target.value;
              setSearch(a);
            }}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        loading={loading}
        dataSource={columnData()}
        style={{ cursor: "pointer" }}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
        expandable={{
          expandedRowRender: (shipmentData) => (
            <p style={{ margin: 0 }}>
              <table>
                <tr className="ta">
                  <th>Item</th>
                  <th>Item Name</th>
                  <th>Item Price</th>
                  <th>Item Quantity</th>
                </tr>
                {shipmentData.shipmentitems?.map((item, index) => (
                  <tr key={index} className="ta">
                    <td>{index + 1}</td>
                    <td>{item.item_name}</td>
                    <td>{item.item_price}</td>

                    <td>{item.item_quantity}</td>
                  </tr>
                ))}
              </table>
            </p>
          ),
          rowExpandable: (shipmentData) => shipmentData.name !== "Not Expandable",
        }}
      />

      <Modal
        open={openView.open}
        onClose={() => setOpenView({ open: false, data: {} })}
        style={{ padding: "1em", height: "fit-content", width: "fit-content" }}
      >
        <ShowAllOrderModalDetails data={openView?.data} />
      </Modal>

      <CustomPagination pageSize={perPageCount.current} page={page} setPage={setPage} total={shipmentCount.current} />
    </div>
  );
}

export default MarkCompleteTable;
