import { Button, Modal } from "antd";
import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { getDateTime } from "../../../utils/dateTime/dateAndTime";
import styles from "./styles.module.css";

function QRModal({ closeQRModal, openQRModal, qrItem }) {
  const [src, setSrc] = useState("");
  const itemRef = useRef();

  useEffect(() => {
    // var qrCodeData = `
    // Shipments Id: ${qrItem?.shipments_unique_id}\n
    // Pick Location: ${qrItem?.pick_location}\n
    // Drop Location: ${qrItem?.drop_location}\n
    // Sender Name: ${qrItem?.rn}\n
    // Phone: ${qrItem?.contact}\n
    // Order Date: ${getDateTime(qrItem?.created_at).date}\n
    // Order Time: ${getDateTime(qrItem?.created_at).time}\n
    // Reciever Name: ${qrItem?.receiver_name}\n
    // Receiver No: ${qrItem?.receiver_number}\n
    // Delivery Type: ${qrItem?.delivery_type}\n
    // `;
    // if (qrItem.delivery_type === "cod") {
    //   qrCodeData += `Total: ${qrItem.total}`;
    // }
    QRCode.toDataURL(qrItem.shipments_unique_id)
      .then((data) => {
        setSrc(data);
      })
      .catch((err) => {});
  });
  return (
    <Modal
      visible={openQRModal}
      onCancel={closeQRModal}
      footer={[
        <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => itemRef.current}
          documentTitle={qrItem?.contact}
          pageStyle="print"
        />,
      ]}
      width={700}
    >
      {/* <div className={styles.containerWrapper}>
        <div className={styles.container} ref={itemRef}>
          <div>
            <img src={src} width={300} />
          </div>
          <div className={styles.itemContainer}>
            <div>Shipments Id:</div>
            <div>{qrItem?.shipments_unique_id}</div>
            <div>Pick Location:</div>
            <div>{qrItem?.pick_location}</div>
            <div>Drop Location:</div>
            <div>{qrItem?.drop_location}</div>
            <div>Sender Name:</div>
            <div>{qrItem?.rn || qrItem?.sender_name}</div>
            <div>Phone:</div>
            <div>{qrItem?.contact}</div>
            <div>Order Date:</div>
            <div>{getDateTime(qrItem?.created_at).date}</div>
            <div>Order Time:</div>
            <div>{getDateTime(qrItem?.created_at).time}</div>
            <div>Reciever Name:</div>
            <div>{qrItem?.receiver_name}</div>
            <div>Receiver No:</div>
            <div>{qrItem?.receiver_number}</div>
            <div>Delivery Type:</div>
            <div>{qrItem?.delivery_type}</div>
            {qrItem?.delivery_type === "cod" && <div>Total:</div>}
            {qrItem?.delivery_type === "cod" && <div>{qrItem.total}</div>}
          </div>
        </div>
      </div> */}
      <div className={styles.container} ref={itemRef}>
        <div className={styles.title}>
          <div>Tracker Number: {qrItem?.shipments_unique_id}</div>
        </div>
        <div className={styles.qrContainer}>
          <div className={styles.gridContainer}>
            <div className={styles.logo}>
              <img src="/static/img/logo.png" width={150} />
            </div>
            <div className={styles.deliveryDetails}>
              <div className={styles.deliveryType}>Delivery Type: {qrItem?.delivery_type}</div>
              <div className={styles.total}>
                Total: Rs.{" "}
                {qrItem?.delivery_type === "cod"
                  ? parseInt(qrItem.total) + parseInt(qrItem.shipment_charge)
                  : 0}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.qrContainer}>
          <div className={styles.gridContainer}>
            <div className={styles.qr}>
              <img src={src} width={280} />
            </div>
            <div className={styles.deliveryDetails}>
              <div className={styles.deliveryType}>
                <div>Receiver Name: {qrItem?.receiver_name}</div>
                <div>Receiver No: {qrItem?.receiver_number}</div>
                <div>Address: {qrItem?.drop_location}</div>
                <div></div>
              </div>
              <div className={styles.total}>Sender Name: {qrItem?.rn || qrItem?.sender_name}</div>
            </div>
          </div>
        </div>
        <div className={styles.qrContainer}>
          <div className={styles.gridContainer}>
            <div></div>
            <div className={styles.createdDate}>
              Order Creation Date: {getDateTime(qrItem?.created_at).date}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default QRModal;
