import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Input, Select, Table, Tag } from "antd";
import { Option } from "antd/lib/mentions";
import cx from "classnames";
import debounce from "lodash.debounce";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { notifyError, notifySuccess } from "../../../routes/ProtectedRoute";
import { getAllRoles } from "../../../utils/api/role";
import { getUsers, updateUserStatus } from "../../../utils/api/users";
import { AbilityContext, Can } from "../../../utils/context/can";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import CustomPagination from "../../Pagination";
import styles from "./styles.module.css";

function ViewUserTable() {
  const { Search } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { width } = useWindowDimensions();

  const [roles, setRoles] = useState([]);

  const [selected, setSelected] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const usersCount = useRef(1);
  const lastPage = useRef(1);
  const ability = useContext(AbilityContext);

  const handleStatus = (id) => {
    return Swal.fire({
      // title: "Are you sure?",
      title: "Do you want to change the status of user?",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      confirmButtonColor: "#1abc9c",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success").then((res1) => {
          updateUserStatus(id).then((res) => {
            if (res.status === "Success") {
              setRefresh((prev) => !prev);
              notifySuccess(res.message);
            }
          });
        });
      }
    });
  };

  const tagChooser = (tags) => {
    switch (tags) {
      case "inactive":
        return "red";

      default:
        return "green";
    }
  };

  const iconChooser = (tags) => {
    switch (tags) {
      case "Ongoing":
        return <SyncOutlined spin />;

      case "Cancelled":
        return <CloseCircleOutlined />;

      case "new":
        return <ClockCircleOutlined />;

      case "Delivered":
        return <CheckCircleOutlined />;

      default:
        return <CheckCircleOutlined />;
    }
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (text, dat) => (
        <div className={styles.nameColumn}>
          <div>
            <img
              className={styles.image}
              src={
                dat.profile_picture
                  ? process.env.REACT_APP_SERVER_URL + dat.profile_picture
                  : `https://ui-avatars.com/api/?name=${dat.name}&background=abcdef`
              }
            />
          </div>
          <div className={styles.nameWrapper}>
            <div className={styles.name}>{dat.name}</div>
            <div className={styles.profileDescription}>{dat.email}</div>
          </div>
        </div>
      ),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 150,
      responsive: ["xl"],
      render: (text) => <span>{text || "No address "}</span>,
    },

    {
      title: "Phone Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 150,
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (tags, data) => (
        <Tag
          color={tagChooser(tags)}
          icon={iconChooser(tags)}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            if (ability.can("user-deactivate", "app")) {
              handleStatus(data.id);
            }
          }}
        >
          {tags}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, item) => (
        <div className="displayFlex">
          <Can I={`${selected}-edit`} a="app">
            <Button
              type="primary"
              className={styles.editButton}
              onClick={() => {
                navigate("/update/" + selected + "/", {
                  state: {
                    data: item,
                    name: item.name,
                    email: item.email,
                  },
                });
              }}
            >
              Edit
            </Button>
          </Can>
          {"delivery-boy" === selected ? (
            <Button
              type="primary"
              onClick={() => {
                if ("delivery-boy" === selected) {
                  navigate("/deliveryboyprofile/", {
                    state: {
                      data: item.id,
                      name: item.name,
                      email: item.email,
                    },
                  });
                }
              }}
            >
              View
            </Button>
          ) : "customer" === selected ? (
            <Button
              type="primary"
              onClick={() => {
                if ("customer" === selected) {
                  navigate("/customerprofile/", {
                    state: {
                      data: item.id,
                      name: item.name,
                      email: item.email,
                      profile_picture: item.profile_picture,
                    },
                  });
                }
              }}
            >
              View
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  //states
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const perPageCount = useRef(1);

  const returnTypeOfUser = (role) => {
    switch (role) {
      case "Admin":
        return "admin";
      case "Customer":
        return "customer";
      case "Delivery Boy":
        return "delivery-boy";
      case "Warehouse":
        return "warehouse";

      default:
        return "admin";
    }
  };

  const handleSelect = (value) => {
    setPage(1);
    setSelected(value);
    navigate({ pathname: "/viewusers/", search: `?type=${value}` });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

  const getUsersData = async (signal) => {
    setLoading(true);
    await getUsers(selected, page, search, signal)
      .then((res) => {
        if (res.status === "Success") {
          if (page == 1) {
            lastPage.current = res.data.last_page;
            usersCount.current = res.data.total;
            perPageCount.current = res.data.users.length;
          }
          setUsers(res.data.users);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          console.log("Fetch Aborted");
        } else {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getAllRoles(abortCont.signal).then((res) => {
      let roles = [];
      res.data.forEach(function (item) {
        roles.push(item.name);
      });
      setRoles(roles);
      for (let i = 0; i <= roles.length; i++) {
        if (ability.can(roles[i] + "-list", "app")) {
          setSelected(searchParams.get("type") ? searchParams.get("type") : roles[i]);
          break;
        }
      }
    });
    return () => abortCont.abort();
  }, []);

  useEffect(() => {
    const abortCont = new AbortController();
    if (selected) {
      getUsersData(abortCont.signal);
    }
    return () => abortCont.abort();
  }, [page, selected, search, refresh, roles]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className="displayFlex">
          {roles.map((dat, i) => (
            <Can I={dat + "-list"} a="app" key={i}>
              <div
                onClick={() => {
                  setPage(1);
                  setSelected(dat);
                  navigate({ pathname: "/viewusers/", search: `?type=${dat}` });
                }}
                className={cx(styles.tabButton, dat === selected ? styles.selected : null)}
              >
                {dat}
              </div>
            </Can>
          ))}
        </div>
        <Select
          style={{ width: 150, textTransform: "capitalized" }}
          onChange={handleSelect}
          defaultValue="All"
          className={styles.tabSelector}
        >
          {roles.map((item, index) => (
            <Option value={item} index={index}>
              {item}
            </Option>
          ))}
        </Select>
        <div>
          <Search placeholder="Search User" onChange={debouncedChangeHandler} />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        loading={loading}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={users.map((dat, i) => {
          return {
            ...dat,
            sn: (page - 1) * perPageCount.current + i + 1,
            key: i,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
        rowClassName={(a, b) => {
          return b % 2 ? styles.row : null;
        }}
      />
      <CustomPagination
        pageSize={perPageCount.current}
        page={page}
        setPage={setPage}
        lastPage={lastPage.current}
        total={usersCount.current}
      />
    </div>
  );
}

export default ViewUserTable;
