import { Menu } from "antd";
import cx from "classnames";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AbilityContext } from "../../utils/context/can";
import menuItems from "./menuItems";
import styles from "./styles.module.css";

function Sidebar({ isCollapsed }) {
  const { SubMenu } = Menu;

  const navigate = useNavigate();
  const location = useLocation();

  const ability = useContext(AbilityContext);
  return (
    <div className={styles.container}>
      <Menu
        className={styles.menu}
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={["sub1"]}
        style={{ height: "100vh", backgroundColor: "var(--theme-yellow)" }}
      >
        {!isCollapsed ? (
          <div className={cx(styles.logo)}>
            <img src="/static/img/logo.png" />
          </div>
        ) : (
          <div className={styles.title}>HS</div>
        )}
        {menuItems.map((menuItem, index) =>
          menuItem.subNav ? (
            <SubMenu className={styles.menu} icon={menuItem.icon} title={menuItem.title} id="sidebar" key={index}>
              {menuItem.subNav.map((subNavItem, index) => (
                <Menu.Item
                  key={subNavItem.path}
                  icon={subNavItem.icon}
                  className={cx(styles.menuTitle)}
                  onClick={() => navigate(subNavItem.path)}
                >
                  {subNavItem.title}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            ability.can(menuItem.permissionRequired, "app") && (
              <Menu.Item
                className={styles.menu}
                icon={menuItem.icon}
                key={menuItem.path}
                onClick={() => navigate(menuItem.path)}
              >
                {menuItem.title}
              </Menu.Item>
            )
          )
        )}
      </Menu>
    </div>
  );
}

export default Sidebar;
