import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../routes/ProtectedRoute";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { getPermision, storeRoleWithPermission } from "./../../utils/api/role";
import styles from "./styles.module.css";

const formData = [
  { label: "Title", name: "title", message: "Title", placeholder: "Title" },
];

function Role() {
  const [permisions, setPermisions] = useState([]);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const callForPermision = (signal) => {
    getPermision(signal)
      .then((res) => {
        if (res.status === "Success") {
          setPermisions(res.data);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
          //console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {});
  };

  const handleSubmit = () => {
    const data = [...permisions];
    const filteredArray = data
      .filter((dat) => dat.checked === true)
      .map((dat) => {
        return dat.name;
      });
    if (title.length === 0 && data.length === 0) {
      return;
    }

    let sendData = {
      name: title,
      permission: filteredArray,
    };

    storeRoleWithPermission(sendData)
      .then((res) => {
        if (res.status === "Success") {
          // setTitle((dat) => (dat = ""));
          // setPermisions((permission) =>
          //   permission.map((item) => {
          //     return { ...item, checked: false };
          //   })
          // );
          navigate("/viewroles/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    callForPermision(abortCont.signal);
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>Add Role</h2>
          <p className={styles.subtitle}>Admin can add warehouse from here</p>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[, 12]}>
          {formData.map((item, index) => (
            <Col
              key={index}
              xxl={{ span: 8 }}
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              {
                <Form.Item
                  label={item.label}
                  name={item.name}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 22 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your ${item.message}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={item.placeholder}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>
              }
            </Col>
          ))}
        </Row>

        <p style={{ marginBottom: "0.5em" }}>Permission</p>

        <Row>
          {permisions.map((item, index) => (
            <Col span={8} key={index}>
              <Checkbox
                checked={item?.checked}
                onChange={(e) => {
                  const data = [...permisions];
                  data[index].checked = e.target.checked;
                  setPermisions(data);
                }}
              >
                {item.name}
              </Checkbox>
            </Col>
          ))}
        </Row>

        <Row style={{ marginTop: "1.5em" }}>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button
                onClick={handleSubmit}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Role;
