import { Button, Form, Input } from "antd";
import { useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../components/Loading";
import { AbilityContext } from "../../utils/context/can";
import { getCookie, setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { login } from "./../../utils/api/auth";
import styles from "./styles.module.css";

function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
    device_token: "",
  });
  const [loading, setLoading] = useState(false);
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (data.email.length === 0 || data.password.length === 0) {
      return;
    }

    setLoading(true);
    let sendingdata = {
      ...data,
      device_token: getCookie("logistic-device-token"),
    };
    login(sendingdata)
      .then((res) => {
        if (res.status === "Success") {
          if (res.data.permissions.includes("admin-screen-login")) {
            setCookie("login-token", res.data.token);
            setCookie("logistic-user-data", res.data);
            let abilities = res.data.permissions.map((e) => ({
              action: e,
              subject: "app",
            }));
            abilities.push({ action: "none", subject: "app" });
            ability.update(abilities);
            // setCookie("id", res.data.id);
            navigate("/");
            setLoading(false);
          } else {
            toast.error("Username or password incorrect");
            setLoading(false);
          }
        } else {
          toast.error(res.data);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, toast.error);
        setLoading(false);
      });
  };

  return getCookie("login-token") ? (
    <Navigate to="/" />
  ) : (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <div className={styles.logoContainer}>
              <img src="/static/img/logo.png" />
            </div>
            <div className={styles.formContainer}>
              <h1 className={styles.title}>Log In</h1>
              <Form
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onSubmitCapture={() => handleSubmit()}
              >
                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[{ required: true, message: "Please input your email!" }]}
                >
                  <Input
                    type="email"
                    value={data.email}
                    onChange={(e) =>
                      setData({
                        ...data,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                  className={styles.lastFormItem}
                >
                  <Input.Password
                    value={data.password}
                    onChange={(e) =>
                      setData({
                        ...data,
                        password: e.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Link to="/forgotpassword/" className={styles.forgotLink}>
                  Forgot Password?
                </Link>

                <Form.Item wrapperCol={{ span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
