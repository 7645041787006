import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Loading from "./components/Loading";
import { getTokenLogin } from "./firebase";
import ForgotPassword from "./pages/forgotPassword";
import Login from "./pages/login";
import ProtectedRoute from "./routes/ProtectedRoute";
import { isLogged } from "./utils/api/auth";
// import { onMessageListener } from "./firebase";
import { AbilityContext } from "./utils/context/can";
import { clearCookie, getCookie } from "./utils/cookie/cookies";

const RedirectPage = () => {
  console.log(getCookie("login-token"));
  return getCookie("login-token") ? <Navigate to="/" /> : <Navigate to="/login/" />;
};

function App() {
  useEffect(() => {
    getTokenLogin();
  }, []);
  const ability = useContext(AbilityContext);

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    try {
      let res = await isLogged();
      if (res.data.logged_in === false) {
        clearCookie("login-token");
        setLoading(false);
        return;
      }

      let abilities = res.data.permissions.map((e) => ({
        action: e,
        subject: "app",
      }));
      abilities.push({ action: "none", subject: "app" });
      ability.update(abilities);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Router>
      <Routes>
        <Route path="/login/" element={<Login />} />
        <Route path="/forgotpassword/" element={<ForgotPassword />} />
        <Route path="*" element={<ProtectedRoute />} />
      </Routes>
    </Router>
  );
}

export default App;
